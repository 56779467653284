<div class="bouwapp-quill">
  <div class="bouwapp-quill__toolbar">
    <div
      id="quill-toolbar-{{ randomNumber }}"
      class="bouwapp-quill__toolbar__markup"
    >
      <button class="ql-bold"></button>
      <button class="ql-italic"></button>
      <button class="ql-underline"></button>
      <button class="ql-link"></button>
      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>
      <button class="ql-emoji"></button>
    </div>
    <span class="character-counter" *ngIf="characterCountText">
      <app-character-count
        [text]="characterCountText"
        [limit]="characterLimit"
      ></app-character-count>
    </span>
  </div>
  <div class="bouwapp-quill__editor notranslate" [class.disabled]="readOnly">
    <quill-editor
      [readOnly]="readOnly"
      [placeholder]="placeholder"
      [formControl]="control"
      [modules]="modules"
      [formats]="formats"
    ></quill-editor>
  </div>
  <app-loader [text]="false" *ngIf="project" [hidden]="!readOnly"></app-loader>
  <app-ai-text
    *ngIf="project || language"
    [textControl]="control"
    [language]="language"
    [project]="project"
    (loading)="aiTextLoading($event)"
  ></app-ai-text>
</div>
