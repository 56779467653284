import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '../../../services/project.service';
import { Project } from '../../../interfaces/project';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  NgForm,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ErrorService } from '../../../services/error.service';
import { ProjectDataBusService } from '../../../services/project-data-bus.service';
import * as moment from 'moment';
import { DetailGeneralLocationComponent } from './detail-general/detail-general-location.component';
import { ProjectStatus } from 'src/app/enums/project-status';
import { ModalComponent } from 'src/app/components/modal.component';
import { DetailGeneralContactComponent } from './detail-general/detail-general-contact.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LocationStrategy, NgIf, NgClass, DecimalPipe } from '@angular/common';
import { Module } from '../../../enums/module';
import { AccessService } from 'src/app/services/access.service';
import { AccessControlList } from 'src/app/interfaces/access-control-list';
import { SecurityVoter } from 'src/app/security/security-voter';
import {
  LatitudeValidator,
  LongitudeValidator,
} from '../../../shared/location.validator';
import { TranslateModule } from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import { ModalComponent as ModalComponent_1 } from '../../../components/modal.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { DetailGeneralWfsComponent } from './detail-general/detail-general-wfs.component';
import { DetailProjectsComponent } from './detail-projects.component';
import { DetailDocumentsComponent } from './detail-documents.component';
import { DetailGeneralTargetsComponent } from './detail-general/detail-general-targets.component';
import { DetailGeneralPlanningComponent } from './detail-general/detail-general-planning.component';
import { DetailGeneralInfoComponent } from './detail-general/detail-general-info.component';
import { SuccessMessageComponent } from '../../../components/success-message.component';
import { AccessDirective } from '../../../directives/access.directive';
import { LoadingDirective } from '../../../directives/loading.directive';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';
import { platform } from '../../../services/platform.service';
import { ThemeService } from 'src/app/services/theme.service';
import { VersionDirective } from 'src/app/directives/version.directive';
import { TwoColumnsLayout } from 'src/app/components/two-columns-layout/two-columns-layout.component';
import { StickySubmitComponent } from 'src/app/components/sticky-submit/sticky-submit.component';

@Component({
  selector: 'app-default-projects-detail-general',
  templateUrl: 'detail-general.component.html',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    LoadingDirective,
    ReactiveFormsModule,
    AccessDirective,
    SuccessMessageComponent,
    FormChangeDetectorDirective,
    DetailGeneralInfoComponent,
    DetailGeneralPlanningComponent,
    DetailGeneralLocationComponent,
    DetailGeneralContactComponent,
    DetailGeneralTargetsComponent,
    DetailDocumentsComponent,
    DetailProjectsComponent,
    DetailGeneralWfsComponent,
    ProgressbarModule,
    InlineSVGModule,
    ModalComponent_1,
    ClipboardModule,
    NgClass,
    DecimalPipe,
    VersionDirective,
    TranslateModule,
    TwoColumnsLayout,
    StickySubmitComponent,
  ],
})
export class DetailGeneralComponent implements OnInit {
  @ViewChild(DetailGeneralLocationComponent)
  locationComponent: DetailGeneralLocationComponent;
  @ViewChild(DetailGeneralContactComponent)
  contactComponent: DetailGeneralContactComponent;
  @ViewChild('formRef') formRef: NgForm;
  @ViewChild('previewModal', { static: true }) public modal: ModalComponent;
  @ViewChild(DetailGeneralInfoComponent)
  detailGeneralInfo!: DetailGeneralInfoComponent;

  public create: boolean = false;
  public uploading: boolean = false;
  public generalFormSaved: boolean = false;
  public loading: boolean = false;
  public isConcept: boolean = false;
  public isSuccess: boolean = false;
  public copied: boolean = false;
  public dateRangeValid: boolean = true;
  public newProject: boolean = false; // V1

  public generalForm: FormGroup;

  public ProjectStatus = ProjectStatus;
  public project: Project;

  public logo: SafeResourceUrl;
  public Module = Module;
  public version: number;
  public currentIndex: number = 0;
  public previewUrl: string = '';
  public steps: string[];

  private accessControlList: AccessControlList;

  constructor(
    private errorService: ErrorService,
    private formBuilder: FormBuilder,
    private projectService: ProjectService,
    private router: Router,
    private projectDataBusService: ProjectDataBusService,
    private sanitizer: DomSanitizer,
    private location: LocationStrategy,
    private accessService: AccessService,
    private themeSerivce: ThemeService,
    private route: ActivatedRoute,
  ) {
    this.createGeneralForm();

    this.steps = [
      'info',
      'planning',
      'location',
      'contact',
      'targets',
      'summary',
    ];

    this.projectDataBusService.projectObservable.subscribe((project) => {
      this.project = project;
      this.create = project.create;
      this.newProject = this.newProject || project.create;
      if (project.id) {
        this.previewUrl =
          'https://' +
          this.project.pwaDomain +
          '/projects/' +
          project.slug +
          '/updates?preview_token=' +
          project.previewToken;
      }
      this.isConcept = project.status == ProjectStatus.UNPUBLISHED;
      if (!this.isConcept) {
        this.setLocationValidators();
      }
      this.generalForm.patchValue(project);

      this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(platform.logo);
    });

    accessService.accessControlList.subscribe((acl) => {
      this.accessControlList = acl;
    });
  }

  async ngOnInit() {
    this.version = await this.themeSerivce.getVersion();

    if (this.version === 2) {
      this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(
        platform.logo.replace('.png', '_beta.png'),
      );

      this.setLocationValidators();
    }
  }

  ngAfterViewInit() {
    const element = document.querySelector('#' + this.route.snapshot.fragment);
    if (element) element.scrollIntoView();
  }

  /**
   * @returns {void}
   */
  private createGeneralForm(): void {
    this.generalForm = this.formBuilder.group({
      name: ['', Validators.required],
      customer: [null, Validators.required],
      participants: [''],
      description: ['', Validators.required],
      sector: [null],
      language: ['nl'],
      coverImage: [''],
      logo: [''],
      status: [''],
      locationLat: [''],
      locationLong: [''],
      locationCity: [''],
      locationShapeFileData: [''],
      wfsProjectNumber: [''],
      wfsEstablishmentName: [''],
      wfsComment: [''],
      startDate: [moment().format(), Validators.required],
      startType: ['EXACT_DATE'],
      endDate: [moment().format(), Validators.required],
      endType: ['EXACT_DATE'],
    });

    this.generalForm.get('startDate').valueChanges.subscribe((startDate) => {
      this.validateDatesForProject(
        startDate,
        this.generalForm.get('endDate').value,
      );
    });

    this.generalForm.get('endDate').valueChanges.subscribe((endDate) => {
      this.validateDatesForProject(
        this.generalForm.get('startDate').value,
        endDate,
      );
    });

    this.generalForm.get('language').valueChanges.subscribe((language) => {
      this.projectDataBusService.emitLangauge(language);
    });
  }

  getAllErrors(formGroup: FormGroup): { [key: string]: any } {
    const errors: { [key: string]: any } = {};

    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup) {
        const groupErrors = this.getAllErrors(control);
        if (Object.keys(groupErrors).length > 0) {
          errors[key] = groupErrors;
        }
      } else {
        if (control.errors) {
          errors[key] = control.errors;
        }
      }
    });

    if (formGroup.errors) {
      errors['formGroup'] = formGroup.errors;
    }

    return errors;
  }

  getFormValidationErrors() {
    Object.keys(this.generalForm.controls).forEach((key) => {
      const controlErrors = this.generalForm.get(key)?.errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach((errorKey) => {
          console.log(
            'Key control: ' + key + ', Error: ' + errorKey + ', Error value:',
            controlErrors[errorKey],
          );
        });
      }
    });
  }

  async saveGeneral(
    shouldContinue = false,
    shouldNotify = true,
  ): Promise<void> {
    if (this.uploading) {
      return;
    }
    this.formRef?.ngSubmit?.emit();

    this.generalFormSaved = false;
    // this will allow submission without valid type, but result in an empty input
    this.locationComponent?.shapeFileInput?.ignoreInvalidFile();
    this.generalForm.get('locationShapeFileData').updateValueAndValidity();

    this.errorService.markFormGroupTouchedAndDirty(this.generalForm);

    if (this.generalForm.get('startDate').value !== null) {
      this.generalForm
        .get('startDate')
        .patchValue(
          moment(this.generalForm.get('startDate').value)
            .set({ h: 11, m: 0, s: 0 })
            .utcOffset('+01:00', true)
            .format(),
        );
    }

    if (this.generalForm.get('endDate').value !== null) {
      this.generalForm
        .get('endDate')
        .patchValue(
          moment(this.generalForm.get('endDate').value)
            .set({ h: 11, m: 0, s: 0 })
            .utcOffset('+01:00', true)
            .format(),
        );
    }

    this.validateDatesForProject(
      this.generalForm.get('startDate').value,
      this.generalForm.get('endDate').value,
    );

    if (
      this.version === 2 &&
      this.contactComponent &&
      !this.contactComponent.contactForm.valid
    ) {
      this.errorService.scrollToInvalidFormGroup();
      return;
    }

    let shouldRedirect = false;

    if (this.generalForm.valid && this.dateRangeValid) {
      this.loading = true;
      this.projectDataBusService.emitIsLoading(true);

      try {
        const data = this.generalForm.getRawValue();
        let project: Project = null;

        if (!this.create) {
          project = await this.projectService.update(this.project.slug, data);
          project.enabledModules = this.project.enabledModules;
          if (project.hasParentProject)
            project.parentProject.enabledModules =
              this.project.parentProject.enabledModules;
        } else {
          data.enabledModules = [];
          project = await this.projectService.create(data);
          await this.accessService.reloadAccessControlList();
          await this.projectService.reloadProjectList();
          shouldRedirect = true;
          this.create = false;
        }

        if (shouldContinue && this.version === 1) {
          this.nextStep();
        }

        if (shouldNotify) {
          this.generalFormSaved = true;
        }

        this.generalForm.markAsPristine();
        this.projectService.updateLastEdited(project);
        this.projectDataBusService.emitProject(project);

        if (this.version === 2) {
          if (this.contactComponent) {
            await this.contactComponent.saveContact();
          }

          if (shouldRedirect) {
            this.router.navigate(['/'], {
              state: { project: project },
            });
          }

          this.isSuccess = true;
          this.projectDataBusService.emitIsConcept(false);
        }
      } catch (error) {
        console.error(error);
        this.errorService.parseErrorsToForm(this.generalForm, error.error, {
          coverImageFile: 'coverImage',
        });
      } finally {
        this.loading = false;
        this.projectDataBusService.emitIsLoading(false);
      }
    } else {
      if (this.isConcept) {
        this.generalForm.get('status').patchValue(ProjectStatus.UNPUBLISHED);
      }
      this.errorService.scrollToInvalidFormGroup();
    }
  }

  setLocationValidators() {
    this.generalForm
      .get('locationLat')
      .setValidators([Validators.required, LatitudeValidator()]);
    this.generalForm
      .get('locationLong')
      .setValidators([Validators.required, LongitudeValidator()]);
    this.generalForm.get('locationLat').updateValueAndValidity();
    this.generalForm.get('locationLong').updateValueAndValidity();
  }

  nextStep() {
    //make latlong required once we arrive at the location step, and keep it like that for the remaining steps
    if (this.steps[this.currentIndex + 1] == 'location') {
      this.setLocationValidators();
    }

    if (this.currentIndex + 1 == this.steps.length) {
      this.isSuccess = true;
      this.projectDataBusService.emitIsConcept(false);
    } else {
      this.currentIndex++;
    }
    window.scroll(0, 0);
  }

  public startUploading() {
    this.uploading = true;
    this.projectDataBusService.emitIsLoading(true);
  }

  public stopUploading(event) {
    this.uploading = false;
    this.projectDataBusService.emitIsLoading(false);
  }

  public validateDatesForProject(startDate, endDate) {
    this.dateRangeValid = true;
    if (moment(endDate) < moment(startDate)) {
      if (this.version === 1) {
        const nextDay = moment(startDate).add(1, 'days').format();
        this.generalForm.get('endDate').setValue(nextDay);
      }

      this.dateRangeValid = false;
    }
  }

  textCopied() {
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 2000);
  }

  openPreview() {
    window.open(this.previewUrl, '_blank');
  }

  openProject(project: Project) {
    if (!SecurityVoter.canViewProject(this.accessControlList, project)) return;

    this.router.navigate(['/projects', project.slug]);

    window.scroll(0, 0);
  }

  async saveConcept(shouldContinue: boolean, shouldNotify: boolean) {
    let step = this.version === 2 ? null : this.steps[this.currentIndex];

    switch (step) {
      case 'contact':
        await this.contactComponent.saveContact();
      case 'summary':
        this.contactComponent.saveContact();
      default:
        this.generalForm.get('status').patchValue(ProjectStatus.UNPUBLISHED);
        await this.saveGeneral(shouldContinue, shouldNotify);
        if (this.generalForm.valid) {
          const projectURL = '/projects/' + this.project.slug + '/general';
          if (this.version === 1) {
            this.location.pushState(
              this.project.id,
              this.project.name,
              projectURL,
              '',
            );
          }
        } else {
          return;
        }

        if (
          this.version === 2 &&
          (!this.contactComponent.contactForm.valid || !this.dateRangeValid)
        ) {
          return;
        }
    }

    this.projectDataBusService.emitProject(
      await this.projectService.fetch(this.project.slug),
    );
  }

  showPreview() {
    if (this.modal) {
      this.modal.open();
    }
  }

  publish() {
    this.generalForm.get('status').patchValue(ProjectStatus.PUBLISHED);

    if (this.project?.id) {
      this.saveGeneral(true);
      return;
    }
    this.saveGeneral(true, false);
  }

  save() {
    if (this.isConcept) {
      this.generalForm.get('status').patchValue(ProjectStatus.UNPUBLISHED);
    }

    this.saveGeneral(true);
  }

  public getTranslationKey(languageCode: string): string | undefined {
    const language = this.detailGeneralInfo?.languages.find(
      (lang) => lang.value === languageCode,
    );
    return language ? language.name : 'dutch';
  }
}
