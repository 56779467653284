<div [formGroup]="generalForm()" class="form-group">
  @if (!withoutText) {
    <p *appVersion="1">
      {{ 'projects.detail.general.planning.description' | translate }}
    </p>
    <p *appVersion="2">
      {{ 'projects.detail.general.planning.subtitle' | translate }}
    </p>
  }
  <div class="form-split">
    <div>
      <div class="form-date">
        <p>{{ 'projects.detail.general.planning.start' | translate }}</p>
        <app-form-group [form]="generalForm()" errorPath="startDate">
          <app-date-picker
            *appVersion="1"
            formControlName="startDate"
            [typeControl]="generalForm().get('startType')"
          >
          </app-date-picker>
          <app-date-picker-v2
            *appVersion="2"
            formControlName="startDate"
            [typeControl]="generalForm().get('startType')"
          >
          </app-date-picker-v2>
        </app-form-group>
      </div>
    </div>
    <div>
      <div class="form-date">
        <p>{{ 'projects.detail.general.planning.end' | translate }}</p>
        <app-form-group [form]="generalForm()" errorPath="endDate">
          <app-date-picker
            *appVersion="1"
            formControlName="endDate"
            [typeControl]="generalForm().get('endType')"
          >
          </app-date-picker>
          <app-date-picker-v2
            *appVersion="2"
            formControlName="endDate"
            [typeControl]="generalForm().get('endType')"
          >
          </app-date-picker-v2>
        </app-form-group>
      </div>
    </div>
  </div>

  <ng-container *appVersion="1">
    <div *ngIf="!dateRangeValid()" class="form-date__notice">
      {{ 'projects.detail.general.planning.date.invalid' | translate }}
    </div>
  </ng-container>
  <ng-container *appVersion="2">
    <div *ngIf="!dateRangeValid()" class="form-date__notice">
      {{ 'projects.detail.announcement.date.range.invalid' | translate }}
    </div>
  </ng-container>
</div>
