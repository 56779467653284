<div class="app two-factor-screen">
  <main class="page">
    <div class="row align page__content">
      <div>
        <img [src]="logo" />
      </div>
      <div class="two-factor-screen__title">
        <h2>{{ 'two.factor.title' | translate }}</h2>
        @if (!loading) {
          @if (step === 2) {
            <p>
              @if (method === 'sms') {
                {{ 'two.factor.description.mobile' | translate }}
              } @else {
                {{ 'two.factor.description.email' | translate }}
              }
            </p>
          } @else if (step === 1) {
            <p>{{ 'two.factor.enter' | translate }}</p>
          }
        }
      </div>
    </div>
    @if (loading) {
      <app-loader></app-loader>
    }
    <section class="profile">
      <div class="row align" *ngIf="!loading">
        @if (step === 0) {
          <app-two-factor-method-switcher
            (formSubmitted)="handleMethodChange($event)"
            [projectUser]="projectUser"
          ></app-two-factor-method-switcher>
        } @else if (step === 1) {
          <form
            [formGroup]="form"
            class="form"
            [@fadeInOut]="'in'"
            (ngSubmit)="submit()"
          >
            <label for="phoneNumber">{{
              'smart_reporting.notice_create.phoneNumber' | translate
            }}</label>
            <app-form-group [form]="form" errorPath="phoneNumber">
              <angular-intl-phone
                #phoneComponent
                formControlName="phoneNumber"
                [config]="phoneConfig"
              ></angular-intl-phone>
            </app-form-group>

            <button
              class="button button--m button--flat bg--orange"
              [appLoading]="loading"
            >
              {{ 'conversation.submit' | translate }}
            </button>
          </form>
        } @else if (step === 2) {
          <form
            [formGroup]="twoFaForm"
            class="form"
            [@fadeInOut]="'in'"
            (ngSubmit)="submit()"
          >
            <div class="bouwapp-group__item">
              <app-form-group>
                <div *ngIf="resendSuccess">
                  {{ 'two.factor.resendSuccess' | translate }}
                </div>
                <div class="errors">
                  <div *ngIf="invalidCode" class="invalidCode">
                    <h3>{{ 'two.factor.invalidCode' | translate }}</h3>
                  </div>
                  <div *ngIf="invalidCode && loginCounter > 0">
                    {{ 'two.factor.remaining' | translate }}:
                    {{ 3 - loginCounter }}
                  </div>
                  <div *ngIf="error">
                    {{ 'two.factor.cannotResend' | translate }}
                  </div>
                </div>

                <input
                  type="text"
                  class="form-control"
                  name="code"
                  formControlName="code"
                  autofocus
                />
                <div
                  class="invalidTwoFa errors"
                  *ngIf="twoFaForm.touched && twoFaForm.invalid"
                >
                  {{ 'two.factor.invalid' | translate }}
                </div>
              </app-form-group>
            </div>
            <button
              class="button button--m button--flat bg--orange"
              [appLoading]="sended"
            >
              {{ 'two.factor.button' | translate }}
            </button>
            <button
              (click)="resend()"
              class="resend button button--m button--flat bg--grey"
              [appLoading]="sended"
            >
              {{ 'two.factor.resend' | translate }}
            </button>
            <button
              *ngIf="!needMethod"
              (click)="resend(getResendMethod())"
              class="resend button button--m button--flat bg--blue"
              [appLoading]="sended"
            >
              {{ 'two.factor.send.via' | translate }}
              @if (method === 'sms') {
                {{ 'two.factor.method.email' | translate }}
              } @else {
                {{ 'two.factor.method.sms' | translate }}
              }
            </button>
          </form>
        }
      </div>
    </section>
  </main>
  <app-footer></app-footer>
</div>
