<div class="bouwapp-box__self__children" *appVersion="1">
  <app-loader *ngIf="loading"></app-loader>

  <div class="bouwapp-box__self__children__head">
    <p>{{ 'projects.detail.projects.title' | translate }}</p>
    <a
      (click)="openChildProjectAdd()"
      appAccess
      role="manager"
      [project]="project"
      >{{ 'projects.detail.projects.new' | translate }}</a
    >
  </div>

  <p
    class="no-results"
    *ngIf="
      (!project.childrenProjects || !project.childrenProjects.length) &&
      !loading
    "
  >
    {{ 'projects.detail.projects.empty' | translate }}
  </p>

  <ul *ngIf="project.childrenProjects && !loading">
    <li class="project-child" *ngFor="let child of project.childrenProjects">
      <div
        class="project-child__icon"
        inlineSVG="/assets/img/icons/location--dark.svg"
      ></div>
      <div class="project-child__content">
        <div>
          <p (click)="openProject(child)">{{ child.name }}</p>
          <div>
            <span>{{ child.participants }}</span>
            <span *ngIf="child.startDate">
              {{ child.startDate | dateType : child.startType }}
            </span>
          </div>
        </div>
        <div>
          <span
            (click)="confirmDeleteChildProject.confirmDelete(child)"
            appAccess
            role="manager"
            [project]="project"
            >{{ 'projects.detail.projects.delete' | translate }}</span
          >
        </div>
      </div>
    </li>
  </ul>
</div>

<ng-container *appVersion="2">
  <div class="bouwapp-header standalone">
    <div class="bouwapp-header__text">
      <h1>
        {{ 'projects.detail.settings.title.prefix' | translate }}
        -
        {{ 'projects.detail.projects.title.v2' | translate }}
      </h1>
    </div>
  </div>

  <div class="bouwapp-box full">
    <div class="bouwapp-box__title">
      <h2>
        {{ 'projects.detail.projects.title.v2' | translate }}
      </h2>
    </div>
    <div class="bouwapp-box__self">
      <ng-container
        *ngIf="!project.enabledModules?.includes(Module.CHILD_PROJECTS)"
      >
        <div class="bouwapp-box__subheader">
          <p
            [innerHTML]="
              'projects.detail.general.projects.disabled' | translate
            "
          ></p>
        </div>
      </ng-container>
      <ng-container
        *ngIf="project.enabledModules?.includes(Module.CHILD_PROJECTS)"
      >
        <ng-container *ngIf="!project.parentProject">
          <div
            class="bouwapp-box__subheader"
            [class.border]="project.childrenProjects.length"
          >
            <p
              [innerHTML]="
                'projects.detail.general.projects.subtitle' | translate
              "
            ></p>
            <div class="section-controls">
              <button
                (click)="openChildProjectAdd()"
                class="button--black-white"
                appAccess
                role="manager"
                [project]="project"
              >
                <span inlineSVG="/assets/v2/img/icons/plus.svg"></span>
                <p>
                  {{ 'projects.detail.projects.new' | translate }}
                </p>
              </button>
            </div>
          </div>
          <div class="bouwapp-box__self__children">
            <app-loader *ngIf="loading"></app-loader>
            <ul *ngIf="project.childrenProjects && !loading">
              <li
                class="project-child"
                *ngFor="let child of project.childrenProjects"
              >
                <div>
                  <div
                    class="project-child__icon"
                    inlineSVG="/assets/v2/img/icons/location.svg"
                  ></div>
                  <div class="project-child__content">
                    <div>
                      <p (click)="openProject(child)">{{ child.name }}</p>
                      <div>
                        <span>{{ child.participants }}</span>
                        <span *ngIf="child.startDate">
                          {{ child.startDate | dateType : child.startType }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <span
                    (click)="confirmDeleteChildProject.confirmDelete(child)"
                    appAccess
                    role="manager"
                    [project]="project"
                    inlineSVG="/assets/img/icons/trash.svg"
                  >
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </ng-container>
        <ng-container *ngIf="project.parentProject">
          <div class="project-child__empty">
            <p
              [innerHTML]="
                'projects.detail.general.projects.is_child_project'
                  | translate : { name: project.parentProject.name }
              "
            ></p>
            <div
              appAccess
              role="viewer"
              [project]="project.parentProject"
              class="button button--m button--outlined bg--grey--hover"
              (click)="openProject(project.parentProject)"
            >
              {{ 'projects.detail.general.projects.go_to' | translate }}
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

<section class="projects child create">
  <app-modal
    [title]="'projects.detail.projects.new' | translate"
    #addChildProject
    [scroll]="false"
    (onClose)="resetChildProject()"
  >
    <form [formGroup]="childProjectsForm" (submit)="saveChildProject()">
      <app-form-group errorPath="projectId" [form]="childProjectsForm">
        <label>{{ 'projects.detail.projects.modal.label' | translate }}</label>
        <select id="project" formControlName="projectId">
          <option [ngValue]="null" selected disabled>
            {{ 'projects.detail.projects.modal.placeholder' | translate }}
          </option>
          <option
            *ngFor="let project of availableProjects"
            [ngValue]="project.id"
          >
            {{ project.name }}
          </option>
        </select>
      </app-form-group>

      <div class="form-group">
        <button type="submit" class="btn btn-primary" [appLoading]="loading">
          {{ 'projects.detail.projects.modal.save' | translate }}
        </button>
      </div>
    </form>
  </app-modal>
</section>

<app-confirm-delete
  #confirmDeleteChildProject
  (confirmed)="deleteChildProject($event)"
></app-confirm-delete>
