<div class="date-picker" [formGroup]="dateForm">
  <div *ngIf="!dateOnly()">
    <select formControlName="mainType" class="date-picker-type">
      <option value="EXACT_DATE">
        {{ 'date_picker.main_type.exact' | translate }}
      </option>
      <option value="PERIOD">
        {{ 'date_picker.main_type.period' | translate }}
      </option>
    </select>
  </div>

  <div [hidden]="showPeriod">
    <input
      [formControl]="actualDate"
      class="input input--m"
      (change)="patchDateForm($event)"
      type="date"
    />
  </div>

  <div *ngIf="typeControl()" [hidden]="!showPeriod">
    <div class="period">
      <select class="input-lg" formControlName="type">
        <option value="MONTH">
          {{ 'date_picker.type.month' | translate }}
        </option>
        <option value="QUARTER">
          {{ 'date_picker.type.quartile' | translate }}
        </option>
        <option value="YEAR">{{ 'date_picker.type.year' | translate }}</option>
      </select>
      <select
        class="date-picker-month"
        formControlName="month"
        [hidden]="type !== 'MONTH'"
      >
        @for (month of getMonthNames(); track month.value) {
        <option [value]="month.value">
          {{ 'date_picker.month.' + month.label.toLowerCase() | translate }}
        </option>
        }
      </select>
      <select
        class="input-lg"
        formControlName="quarter"
        [hidden]="type !== 'QUARTER'"
      >
        <option value="1">{{ 'date_picker.quartile.q1' | translate }}</option>
        <option value="2">{{ 'date_picker.quartile.q2' | translate }}</option>
        <option value="3">{{ 'date_picker.quartile.q3' | translate }}</option>
        <option value="4">{{ 'date_picker.quartile.q4' | translate }}</option>
      </select>
      <select class="date-picker-year" formControlName="year">
        <option *ngFor="let year of years" value="{{ year }}">
          {{ year }}
        </option>
      </select>
    </div>
  </div>
</div>
