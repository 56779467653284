import { Component, OnInit, ViewChild } from '@angular/core';
import { UserDataService } from '../../../services/user-data.service';
import { User } from '../../../interfaces/user';
import { ProjectService } from '../../../services/project.service';
import { Project } from '../../../interfaces/project';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Router, RouterLink } from '@angular/router';
import { ProjectDataBusService } from '../../../services/project-data-bus.service';
import { CustomerPickerComponent } from '../../../components/customer-picker.component';
import { Customer } from '../../../interfaces/customer';
import { SecurityVoter } from '../../../security/security-voter';
import { AccessService } from '../../../services/access.service';
import { ProjectStatus } from '../../../enums/project-status';
import { Sort } from '../../../interfaces/sort';
import { UpdatePickerComponent } from 'src/app/components/update-helpers/update-picker.component';
import { DateTypePipe } from '../../../pipes/date-type.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { FormGroupComponent } from '../../../components/form-group.component';
import { ModalComponent } from '../../../components/modal.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { LoaderComponent } from '../../../components/loader.component';
import { AccessDirective } from '../../../directives/access.directive';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter, take } from 'rxjs';
import { VersionDirective } from 'src/app/directives/version.directive';

@Component({
  selector: 'app-default-projects-list',
  templateUrl: 'list.component.html',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    VersionDirective,
    AccessDirective,
    UpdatePickerComponent,
    NgFor,
    LoaderComponent,
    InfiniteScrollDirective,
    NgClass,
    InlineSVGModule,
    ModalComponent,
    FormGroupComponent,
    CustomerPickerComponent,
    DatePipe,
    TranslateModule,
    DateTypePipe,
    RouterLink,
  ],
})
export class ListComponent implements OnInit {
  @ViewChild(UpdatePickerComponent) updatePicker: UpdatePickerComponent;
  @ViewChild('successModal', { static: true }) successModal: ModalComponent;
  public user: User;
  public projectStatus = ProjectStatus;
  public project: Project;

  totalItems: number;

  private perPage = 10;

  private page = 1;
  private ready = false;

  public sortItems: Sort[] = [
    {
      field: 'updatedAt',
      direction: 'desc',
      label: 'project.list.sort.direction.updated.descending',
    },
    {
      field: 'updatedAt',
      direction: 'asc',
      label: 'project.list.sort.direction.updated.ascending',
    },
    {
      field: 'name',
      direction: 'asc',
      label: 'project.list.sort.direction.ascending',
    },
    {
      field: 'name',
      direction: 'desc',
      label: 'project.list.sort.direction.descending',
    },
    {
      field: 'popular',
      direction: 'desc',
      label: 'project.list.sort.direction.popular.descending',
    },
    {
      field: 'popular',
      direction: 'asc',
      label: 'project.list.sort.direction.popular.ascending',
    },
  ];

  private sort: Sort = this.sortItems[0];

  private readonly defaultStatuses = [
    ProjectStatus.PUBLISHED,
    ProjectStatus.PRIVATE,
    ProjectStatus.UNPUBLISHED,
  ];

  private search = null;
  private statuses = null;
  public statusCount;
  public loading: boolean = true;

  public projects: Project[];
  public customers: Customer[] = [];
  public customerIds: number[] | null;

  /**
   * Full array of all projects with minimal data, no pagination
   */
  public projectsShortList: Project[];
  public projectShortListForUpdates: Project[];

  @ViewChild(CustomerPickerComponent, { static: true })
  private customerPicker: CustomerPickerComponent;

  public filterForm: FormGroup;

  constructor(
    deviceDetectorService: DeviceDetectorService,
    private accessService: AccessService,
    private userDataService: UserDataService,
    private router: Router,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private projectService: ProjectService,
    private projectDataBusService: ProjectDataBusService,
  ) {
    this.createFilterForm();
    this.getStatusCount();

    if (deviceDetectorService.isDesktop()) {
      this.perPage *= 2;
    }
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.project =
        this.router.getCurrentNavigation()?.extras.state['project'];
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.project) {
      this.successModal.open();
    }
    this.user = await this.userDataService.retrieveUser();
    this.projectDataBusService.shortListObservable.subscribe((shortList) => {
      this.projectsShortList = shortList;
    });

    this.projectDataBusService.shortListObservable
      .pipe(
        filter((n) => n !== null),
        take(1),
      )
      .subscribe(() => this.subscribeFilterUpdateProjectList());
  }

  public async getStatusCount() {
    this.statusCount = await this.projectService.getStatusCount(
      this.search,
      this.customerIds,
    );
  }

  private createFilterForm() {
    const statuses = this.formBuilder.array(
      Object.values(ProjectStatus).map((status) => {
        if (this.defaultStatuses.includes(status)) {
          return this.formBuilder.control(true);
        }
        return this.formBuilder.control(false);
      }),
    );

    this.filterForm = this.formBuilder.group({
      sort: [this.sortItems[0], Validators.required],
      customer: [''],
      search: [''],
      statuses,
      customers: this.formBuilder.array([]),
    });

    this.filterForm.get('sort').setValue(this.sort);
    this.filterForm.valueChanges.subscribe(() => {
      this.updateFilter();
    });
  }

  private async subscribeFilterUpdateProjectList(): Promise<void> {
    this.accessService.accessControlList.subscribe((list) => {
      if (list == null) {
        return;
      }

      this.customers = this.accessService.getCustomersFromACL(list);
      const formCustomers = this.filterForm.get('customers') as FormArray;
      formCustomers.controls = [];

      this.customers.forEach((customer) =>
        formCustomers.push(this.formBuilder.control(true)),
      );

      this.ready = true;
      this.loadProjects();

      if (this.projectsShortList !== null) {
        this.projectShortListForUpdates = this.projectsShortList.filter(
          (item) =>
            SecurityVoter.canEditProject(list, item) === true &&
            item.status !== ProjectStatus.ARCHIVED,
        );

        this.projectShortListForUpdates.sort(function (projectA, projectB) {
          const projectNameA = projectA.name.toUpperCase();
          const projectNameB = projectB.name.toUpperCase();
          return projectNameA < projectNameB
            ? -1
            : projectNameA > projectNameB
              ? 1
              : 0;
        });
      }
    });
  }

  openModal() {
    this.updatePicker.pick();
  }

  updateFilter() {
    this.sort = this.filterForm.get('sort').value as Sort;
    this.search = this.filterForm.get('search').value;
    this.statuses = this.filterForm
      .get('statuses')
      .value.map((item, index) => (!item ? null : this.allStatuses[index]))
      .filter((it) => !!it);
    this.customerIds = this.filterForm
      .get('customers')
      .value.map((item, index) => (!item ? null : this.customers[index].id))
      .filter((it) => !!it);

    if (!this.ready) {
      return;
    }

    this.reset();
    this.loadProjects();
    this.getStatusCount();
  }

  getCoverImageByProject(project: Project): SafeStyle | string {
    if (project.coverImage == null) {
      return '';
    } else {
      return this.sanitizer.bypassSecurityTrustStyle(
        'url(' + project.coverImageThumbnails?.medium + ')',
      );
    }
  }

  openProject(project: Project) {
    this.router.navigate(['/projects', project.slug]);
  }

  async createNewProject(): Promise<void> {
    const customer: Customer = await this.customerPicker.pick();
    if (undefined === customer) {
      return;
    }
    this.router.navigateByUrl('projects?customerId=' + customer.id);
  }

  getStatusClass(project: Project): string {
    switch (project.status) {
      case ProjectStatus.PUBLISHED:
        return 'green';
      case ProjectStatus.UNPUBLISHED:
        return 'dark';
      case ProjectStatus.ARCHIVED:
        return 'grey';
      case ProjectStatus.PRIVATE:
        return 'blue';
    }

    return null;
  }

  async loadProjects(more: boolean = false): Promise<void> {
    this.loading = true;

    if (!this.projects || this.projects.length < this.totalItems) {
      const currentParams = this.currentParamHash;
      const response: any = await this.projectService.list(
        this.page,
        this.perPage,
        this.sort,
        this.search,
        this.statuses,
        this.customerIds,
      );
      const result: Project[] = response['hydra:member'];
      if (result != null && currentParams === this.currentParamHash) {
        this.page += 1;

        if (!this.projects) {
          this.projects = [];
          this.totalItems = response['hydra:totalItems'];
        }

        this.projects = this.projects.concat(result);
      }
    }

    this.loading = false;
  }

  public compareSort(sort1: Sort, sort2: Sort): boolean {
    return (
      sort1.field === sort2.field &&
      sort1.label === sort2.label &&
      sort1.direction === sort2.direction
    );
  }

  private reset() {
    this.page = 0;
    this.totalItems = undefined;
    this.projects = undefined;
  }

  get allStatuses(): ProjectStatus[] {
    return Object.values(ProjectStatus);
  }

  get currentParamHash() {
    return JSON.stringify([
      this.sort,
      this.search,
      this.statuses,
      this.customerIds,
    ]);
  }
}
