<div [formGroup]="generalForm">
  <div class="bouwapp-group__row two">
    <p>
      <span *appVersion="2">{{
        'projects.detail.general.wfs.subtitle' | translate
      }}</span>
      {{ 'projects.detail.general.wfs.description' | translate }}
    </p>
    <div class="bouwapp-group__item">
      <app-form-group [form]="generalForm" errorPath="wfsProjectNumber">
        <label>{{
          'projects.detail.general.wfs.project_number.label' | translate
        }}</label>
        <input
          [placeholder]="
            'projects.detail.general.wfs.project_number.placeholder' | translate
          "
          formControlName="wfsProjectNumber"
        />
      </app-form-group>
    </div>
    <div class="bouwapp-group__item">
      <app-form-group [form]="generalForm" errorPath="wfsEstablishmentName">
        <label>
          {{
            'projects.detail.general.wfs.establishment_name.label' | translate
          }}
        </label>
        <input
          [placeholder]="
            'projects.detail.general.wfs.establishment_name.placeholder'
              | translate
          "
          formControlName="wfsEstablishmentName"
        />
      </app-form-group>
    </div>
  </div>
  <div class="bouwapp-group__row">
    <div class="bouwapp-group__item">
      <app-form-group [form]="generalForm" errorPath="wfsComment">
        <label>{{
          'projects.detail.general.wfs.comment.label' | translate
        }}</label>
        <textarea
          class="textarea"
          [placeholder]="
            'projects.detail.general.wfs.comment.placeholder' | translate
          "
          formControlName="wfsComment"
          appAccess
          role="manager"
          [project]="project"
          property="disabled"
        ></textarea>
      </app-form-group>
    </div>
  </div>
</div>
