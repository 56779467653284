import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputFileComponent } from 'src/app/components/input-file.component';
import { Project } from 'src/app/interfaces/project';
import { centroid } from '@turf/turf';
import { AccessService } from 'src/app/services/access.service';
import { SecurityVoter } from 'src/app/security/security-voter';
import { MapComponent, NgxMapboxGLModule } from 'ngx-mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as mapboxgl from 'mapbox-gl';
import { MapboxService } from 'src/app/services/mapbox.service';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { environment } from 'src/environments/environment';
import { ThemeService } from 'src/app/services/theme.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { InputFileComponent as InputFileComponent_1 } from '../../../../components/input-file.component';
import { NgIf } from '@angular/common';
import { FormGroupComponent } from '../../../../components/form-group.component';
import { InputFileV2Component } from 'src/app/components/input-file-v2.component';
import { VersionDirective } from 'src/app/directives/version.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-detail-general-location',
  templateUrl: './detail-general-location.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormGroupComponent,
    NgIf,
    NgxMapboxGLModule,
    InputFileComponent_1,
    TranslateModule,
    InputFileV2Component,
    VersionDirective,
    InlineSVGModule,
  ],
})
export class DetailGeneralLocationComponent implements OnInit {
  @ViewChild('shapeFileInput') shapeFileInput: InputFileComponent;
  @ViewChild('shapeFileInput2') shapeFileInput2: InputFileV2Component;
  @Input() generalForm: FormGroup;
  @Input() project: Project;
  @ViewChild(MapComponent) public mapComponent: MapComponent;
  @ViewChild('mapContainer', { read: ElementRef })
  public mapContainer: ElementRef;

  public locationShapeFileData: any;
  public shapeFileInfo = false;
  public canEdit = false;
  public canClick: boolean = true;
  public draw?: MapboxDraw;
  public mapReady: boolean = false;
  public marker: mapboxgl.Marker;

  private locationLat: number;
  private locationLong: number;
  public version: number;

  constructor(
    private accessService: AccessService,
    private mapboxService: MapboxService,
    private translateService: TranslateService,
    private themeService: ThemeService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.project.create !== true) {
      this.accessService.accessControlList.subscribe(
        (acl) =>
          (this.canEdit = SecurityVoter.canEditProject(acl, this.project))
      );
    } else {
      this.accessService.accessControlList.subscribe(
        (acl) =>
          (this.canEdit =
            SecurityVoter.hasCreateRole(acl) || SecurityVoter.hasCustomers(acl))
      );
    }
    this.version = await this.themeService.getVersion();
  }

  private placeMarker(location: number[]) {
    this.clearShapeFiles();
    this.clearDrawings();

    if (!this.marker) {
      this.marker = this.mapboxService.drawMarker(
        this.mapComponent,
        location,
        null,
        {},
        31,
        40
      );
    } else {
      this.marker.setLngLat([location[1], location[0]]);
    }
    this.generalForm.patchValue({
      locationLat: location[0],
      locationLong: location[1],
    });
  }

  private removeMarker() {
    if (this.marker !== undefined) {
      this.marker.remove();
    }
    this.marker = undefined;
  }

  public mapClick($event: mapboxgl.MapMouseEvent) {
    if (!this.canClick) {
      return;
    }

    if (this.draw && this.draw.getMode() !== 'simple_select') {
      this.removeMarker();
      return;
    }
    if ($event.lngLat) {
      this.placeMarker([$event.lngLat.lat, $event.lngLat.lng]);
    }
  }

  ngAfterViewInit() {
    this.locationLat = this.project.locationLat || 52.3676;
    this.locationLong = this.project.locationLong || 4.9041;

    this.generalForm.patchValue({
      locationLat: this.project.locationLat || 52.3676,
      locationLong: this.project.locationLong || 4.9041,
    });
  }

  addCustomButtons() {
    const deleteContainer = document.createElement('div');
    deleteContainer.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
    const deleteButton = document.createElement('button');

    if (this.version === 1) {
      const drawButton = document.createElement('button');
      drawButton.innerHTML = this.translateService.instant(
        'projects.detail.general.location.draw.button'
      );
      drawButton.className = 'button btn mapboxgl-ctrl';
      drawButton.onclick = (event) => {
        this.draw.changeMode('draw_polygon');
        this.changeMode(event);
      };
      const drawControl = new mapboxgl.NavigationControl();
      drawControl.onAdd = function (map) {
        const container = document.createElement('div');
        container.className = 'mapboxgl-ctrl-custom-container';
        container.appendChild(drawButton);
        return container;
      };
      this.mapComponent.mapInstance.addControl(drawControl, 'bottom-right');
    }

    deleteButton.onclick = (event) => {
      this.clearShapeFiles();
      this.clearDrawings();
      this.removeMarker();
    };

    const deleteControl = new mapboxgl.NavigationControl();

    deleteControl.onAdd = function (map) {
      const span = document.createElement('img');
      span.setAttribute('src', '/assets/img/icons/delete.svg');

      deleteButton.appendChild(span);
      deleteContainer.appendChild(deleteButton);
      return deleteContainer;
    };

    this.mapComponent.mapInstance.addControl(deleteControl, 'top-right');
  }

  loadShapeFileData(data: string): void {
    this.removeMarker();
    try {
      this.locationShapeFileData = JSON.parse(data);
      this.mapboxService.addShapeFile(
        this.mapComponent,
        this.project.slug === '' ? uuidv4() : this.project.slug,
        this.locationShapeFileData
      );
      this.mapboxService.fitMapToShapeFile(
        this.mapComponent,
        this.locationShapeFileData,
        20
      );
      this.generalForm.patchValue({
        locationLat: this.project.locationLat ?? this.locationLat,
        locationLong: this.project.locationLong ?? this.locationLong,
      } as any);
    } catch (error) {
      console.log(error);
      // invalid JSON object
    }
  }

  async initMap() {
    this.mapComponent.mapInstance.panTo([this.locationLong, this.locationLat]);

    await this.addDrawControl();
    this.addGeocoder();
    if (this.project.locationShapeFileData) {
      this.loadShapeFileData(this.project.locationShapeFileData);
    } else {
      this.placeMarker([this.locationLat, this.locationLong]);
    }

    setTimeout(() => {
      this.mapComponent.mapInstance.resize();
    }, 500);
  }

  addGeocoder() {
    const geocoder = new MapboxGeocoder({
      accessToken: environment.mapboxToken,
      mapboxgl: mapboxgl,
      marker: false,
    });

    geocoder.on('result', (event) => {
      this.clearDrawings();
      this.clearShapeFiles();
      const locationPicked = event.result.center.reverse();
      this.placeMarker(locationPicked);

      this.mapboxService.setLocation(this.mapComponent, locationPicked);
    });

    this.mapComponent.mapInstance.addControl(geocoder, 'top-left');
  }

  async addDrawControl() {
    this.addCustomButtons();

    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {},
      defaultMode: 'simple_select',
    });

    this.mapComponent.mapInstance.addControl(this.draw, 'top-right');
    this.mapComponent.mapInstance.on('draw.create', this.handleDrawCreate);
    this.mapComponent.mapInstance.on('draw.delete', this.updateArea);
    this.mapComponent.mapInstance.on('draw.update', this.updateArea);
    this.mapComponent.mapInstance.on('draw.modechange', this.changeMode);
  }

  changeMode = (e: any) => {
    if (e.mode !== 'simple_select') {
      this.canClick = false;
    } else {
      setTimeout(() => {
        this.canClick = true;
      }, 500);
    }
  };

  handleDrawCreate = (e: any) => {
    const data = this.draw.getAll();
    if (data.features.length > 1) {
      const oldPolygons = data.features.slice(0, -1);
      oldPolygons.forEach((polygon) => {
        this.draw.delete(polygon.id);
      });
    }
    this.updateArea();
  };

  updateArea = () => {
    if (this.marker !== undefined) {
      this.marker.remove();
    }
    this.marker = undefined;
    const data = this.draw.getAll();
    this.clearShapeFiles();
    if (data.features.length === 0) {
      return;
    }
    this.getCenterOfShapeFile(data);
    this.generalForm.patchValue({
      locationShapeFileData: JSON.stringify(data),
    });
    this.locationShapeFileData = data;
  };

  getCenterOfShapeFile(data: any) {
    let center = centroid(data);
    if (
      !this.generalForm.get('locationLat').value &&
      !this.generalForm.get('locationLong').value
    ) {
      this.generalForm.patchValue({
        locationLat: center.geometry.coordinates[1],
        locationLong: center.geometry.coordinates[0],
      } as any);
    }
  }

  clearDrawings() {
    if (!this.draw) {
      return;
    }
    const draws = this.draw?.getAll();

    draws?.features?.forEach((draw) => {
      this.draw.delete(draw.id);
    });
  }

  clearShapeFiles() {
    this.generalForm.patchValue({ locationShapeFileData: null });
    this.mapboxService.clearShapeFiles(this.mapComponent, [this.project.slug]);
    if (this.shapeFileInput) {
      this.shapeFileInput.clear();
      this.shapeFileInput.simpleValue = null;
    }
    if (this.shapeFileInput2) {
      this.locationShapeFileData = null;
    }

    this.generalForm.patchValue({
      locationLat: null,
      locationLong: null,
    } as any);
  }

  getDataLayerStyles() {
    const primary = '#F5B049';

    return {
      clickable: false,
      fillColor: primary,
      strokeColor: primary,
    };
  }

  updateLocationShapeFile(data?: any): void {
    this.clearShapeFiles();

    if (data === null) {
      this.clearDrawings();
      this.locationShapeFileData = null;
      return;
    }
    if (this.version === 2) {
      this.generalForm.get('locationShapeFileData').patchValue(data.preview);
      this.generalForm.get('locationShapeFileData').updateValueAndValidity();
      this.loadShapeFileData(data.preview);
    } else {
      this.loadShapeFileData(atob(data.split('base64,')[1]));
    }
  }

  public toggleShapeFileInfo() {
    this.shapeFileInfo = !this.shapeFileInfo;
  }
}
