import {
  Component,
  EventEmitter,
  input,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ProjectStatus } from 'src/app/enums/project-status';
import { Project } from 'src/app/interfaces/project';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { InputFileComponent } from 'src/app/components/input-file.component';
import { ProjectSector } from 'src/app/enums/project-sector';
import { AccessService } from 'src/app/services/access.service';
import { SecurityVoter } from 'src/app/security/security-voter';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropboxComponent } from '../../../../components/image-cropbox.component';
import { QuillComponent } from '../../../../components/quill.component';
import { AccessDirective } from '../../../../directives/access.directive';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { InputFileComponent as InputFileComponent_1 } from '../../../../components/input-file.component';
import { FormGroupComponent } from '../../../../components/form-group.component';
import { VersionDirective } from 'src/app/directives/version.directive';
import { InputFileV2Component } from 'src/app/components/input-file-v2.component';
import { ThemeService } from 'src/app/services/theme.service';
import { DatePickerV2Component } from 'src/app/components/date-picker-v2.component';
import { platform } from 'src/app/services/platform.service';
import { DetailGeneralPlanningComponent } from './detail-general-planning.component';

@Component({
  selector: 'app-detail-general-info',
  templateUrl: './detail-general-info.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormGroupComponent,
    InputFileComponent_1,
    InlineSVGModule,
    NgIf,
    NgFor,
    NgClass,
    AccessDirective,
    QuillComponent,
    ImageCropboxComponent,
    TranslateModule,
    VersionDirective,
    InputFileV2Component,
    DatePickerV2Component,
    DetailGeneralPlanningComponent,
  ],
})
export class DetailGeneralInfoComponent implements OnInit {
  @Input() generalForm: FormGroup;
  @Input() project: Project;
  @Input() isConcept: boolean;
  readonly dateRangeValid = input<boolean>(false);
  @Output() startUploading = new EventEmitter();
  @Output() stopUploading = new EventEmitter();
  @ViewChild('coverInput') coverInput: InputFileComponent;
  @ViewChild('logoInput') logoInput: InputFileComponent;

  ProjectStatus = ProjectStatus;
  ProjectSector = ProjectSector;
  private coverImage: string;
  private coverImageThumbnail: string;
  private logo: string;
  private logoThumbnail: string;
  public version: number;

  public readonly languages = [
    { value: 'nl', name: 'dutch' },
    { value: 'en', name: 'english' },
    { value: 'da', name: 'danish' },
    { value: 'de', name: 'german' },
  ];

  imageChangedEvent: any;
  uploading: boolean = false;
  showCoverImageEditor: boolean = false;
  showLogoEditor: boolean = false;
  canEdit: boolean = false;
  platform = platform;

  constructor(
    private sanitizer: DomSanitizer,
    private accessService: AccessService,
    private themeService: ThemeService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.setCoverImage(this.project.coverImage);
    this.generalForm.get('coverImage').valueChanges.subscribe((value) => {
      this.setCoverImage(value);
    });

    this.setLogo(this.project.logo);
    this.generalForm.get('logo').valueChanges.subscribe((value) => {
      this.setLogo(value);
    });

    if (this.project.create !== true) {
      this.accessService.accessControlList.subscribe(
        (acl) =>
          (this.canEdit = SecurityVoter.canEditProject(acl, this.project)),
      );
    } else {
      this.accessService.accessControlList.subscribe(
        (acl) =>
          (this.canEdit =
            SecurityVoter.hasCreateRole(acl) ||
            SecurityVoter.hasCustomers(acl)),
      );
    }
  }

  async ngAfterViewInit() {
    this.version = await this.themeService.getVersion();
  }

  getProjectStatusValues(): string[] {
    return Object.values(ProjectStatus);
  }

  getCurrentStatusClass(): string {
    switch (this.generalForm.get('status').value) {
      case ProjectStatus.PUBLISHED:
        return 'green';
      case ProjectStatus.UNPUBLISHED:
        return 'orange';
      case ProjectStatus.ARCHIVED:
        return 'grey';
      case ProjectStatus.PRIVATE:
        return 'grey';
    }

    return '';
  }

  updateCoverImageThumbnail(thumbnail: string): void {
    this.coverImageThumbnail = thumbnail;
  }

  getCurrentCoverImage(): SafeStyle | string {
    if (this.coverImage == null) {
      return '';
    } else {
      let url;

      if (this.coverImageThumbnail != null) {
        url = this.coverImageThumbnail;
      } else {
        url = this.project.coverImageThumbnails?.large;
      }

      return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
    }
  }

  setCoverImage(value: string) {
    this.coverImage = value;
  }

  updateLogoThumbnail(thumbnail: string): void {
    this.logoThumbnail = thumbnail;
  }

  getCurrentLogo(): SafeStyle | string {
    if (this.logo == null) {
      return '';
    } else {
      let url: string;

      if (this.logoThumbnail != null) {
        url = this.logoThumbnail;
      } else {
        url = this.project.logoThumbnails?.small;
      }

      return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
    }
  }

  setLogo(value: string) {
    this.logo = value;
  }

  inputFileStartUploading() {
    this.startUploading.emit();
  }

  inputFileStopUploading(event) {
    this.stopUploading.emit();
  }

  startEditingCoverImage(event) {
    this.imageChangedEvent = event;
    this.showCoverImageEditor = true;
  }

  stopEditingCoverImage() {
    this.showCoverImageEditor = false;
  }

  startEditingLogo(event) {
    this.imageChangedEvent = event;
    this.showLogoEditor = true;
  }

  stopEditingLogo() {
    this.showLogoEditor = false;
  }
}
