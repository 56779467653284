import { registerLocaleData } from '@angular/common';
import { LocaleService } from './services/locale.service';
import localeNl from '@angular/common/locales/nl';
import localeEn from '@angular/common/locales/en';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Observable, from } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import Quill from 'quill';
import * as Emoji from 'quill2-emoji';

export class LazyTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

export function loadLocale(service: LocaleService) {
  return service.currentLocale;
}

registerLocaleData(localeNl, 'nl');
registerLocaleData(localeEn, 'en');

Quill.register('modules/emoji', Emoji);

export function storageFactory(): OAuthStorage {
  return localStorage;
}
