<div class="tickets">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <h1 *appVersion="1">
        {{ 'projects.detail.settings.title' | translate }}
      </h1>
      <h1 *appVersion="2">
        {{ 'projects.detail.settings.title.prefix' | translate }}
        -
        {{ 'project.detail.contact.title' | translate }}
      </h1>
    </div>
    <div class="bouwapp-header__buttons" *appVersion="2">
      <button
        class="primary"
        (click)="saveContact()"
        appAccess
        role="manager"
        [appLoading]="contactComponent.contactLoading || notificationLoading"
      >
        {{ 'project.detail.settings.notifications.save' | translate }}
      </button>
    </div>
  </div>
  <app-sticky-submit
    *appVersion="2"
    [title]="'project.detail.contact.title'"
    [buttonText]="'project.detail.settings.notifications.save'"
    [loading]="contactComponent.contactLoading || notificationLoading"
    (submit)="saveContact()"
  ></app-sticky-submit>

  <div class="bouwapp-box full">
    <div class="bouwapp-box__title">
      <h2>
        {{ 'project.detail.contact.title' | translate }}
      </h2>
    </div>
    <div class="bouwapp-box__self">
      <app-detail-general-contact
        (formSaved)="formSaved()"
        [withSave]="true"
      ></app-detail-general-contact>
    </div>
  </div>

  <div class="bouwapp-box full">
    <div class="bouwapp-box__title">
      <h2>
        {{ 'project.detail.settings.notifications.title' | translate }}
      </h2>
    </div>
    <div class="bouwapp-box__self">
      <div
        class="bouwapp-box__subheader"
        [class.border]="
          notificationForm.get('ticketContactEmails')['controls'].length
        "
      >
        <p>
          {{ 'project.detail.settings.notifications.description' | translate }}
        </p>
        <button
          (click)="addEmptyNotificationMail()"
          class="button--black-white"
          appAccess
          role="manager"
          [project]="project"
        >
          <span inlineSVG="/assets/v2/img/icons/plus.svg"></span>
          <p>
            {{ 'project.detail.settings.notifications.new' | translate }}
          </p>
        </button>
      </div>

      <div class="ticket-notification">
        <span
          *ngIf="
            notificationForm.get('ticketContactEmails')['controls'].length > 0
          "
          >{{ 'project.detail.settings.notifications.label' | translate }}</span
        >
        <form
          [formGroup]="notificationForm"
          appFormChangeDetector
          (submit)="saveNotifications()"
          #formRef="ngForm"
          ngForm
          *ngIf="project"
          appAccess
          role="manager"
          [project]="project"
          property="readonly"
        >
          <app-form-group
            [form]="notificationForm"
            errorPath="ticketContactEmails"
          >
            <ul>
              <li
                *ngFor="
                  let email of notificationForm.get('ticketContactEmails')[
                    'controls'
                  ];
                  let i = index
                "
                formArrayName="ticketContactEmails"
              >
                <div [formGroupName]="i">
                  <input
                    type="email"
                    class="input input--m"
                    [ngClass]="{ invalid: email.invalid }"
                    [attr.placeholder]="
                      'project.detail.settings.notifications.email.placeholder'
                        | translate
                    "
                    formControlName="ticketContactEmail"
                  />
                </div>
                <span
                  *ngIf="i > 0"
                  (click)="deleteContactEmail(i)"
                  inlineSVG="/assets/img/icons/trash.svg"
                ></span>
              </li>
            </ul>
          </app-form-group>
          <div class="flex flex-row" *appVersion="1">
            <button
              class="button button--m button--flat bg--orange"
              [appLoading]="notificationLoading"
              appAccess
              role="manager"
              [project]="project"
            >
              {{ 'project.detail.settings.notifications.save' | translate }}
            </button>
            <span
              (click)="addEmptyNotificationMail()"
              appAccess
              role="manager"
              [project]="project"
              >{{
                'project.detail.settings.notifications.new' | translate
              }}</span
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
