import { Component, OnDestroy, OnInit } from '@angular/core';
import { Survey } from 'src/app/interfaces/survey';
import { Project } from 'src/app/interfaces/project';
import { SurveyService } from 'src/app/services/survey.service';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SurveyStatus } from 'src/app/enums/survey-status';
import { NgArrayPipesModule } from 'ngx-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDeleteComponent } from '../../../../components/confirm-delete.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { AccessDirective } from '../../../../directives/access.directive';
import { Router, RouterLink } from '@angular/router';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { Module } from 'src/app/enums/module';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-detail-feedback-list',
  templateUrl: './list.component.html',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    AccessDirective,
    ReactiveFormsModule,
    NgFor,
    NgClass,
    InlineSVGModule,
    ConfirmDeleteComponent,
    DatePipe,
    TranslateModule,
    NgArrayPipesModule,
  ],
})
export class DetailFeedbackListComponent implements OnInit, OnDestroy {
  project: Project;
  surveys: Survey[];
  orderBy: '-createdAt';
  filterBy = null;
  SurveyStatus = SurveyStatus;
  filterForm: FormGroup;
  projectSubscription: Subscription;

  constructor(
    protected projectDataBusService: ProjectDataBusService,
    private surveyService: SurveyService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.projectSubscription =
      this.projectDataBusService.projectObservable.subscribe((project) => {
        this.project = project;
        if (!this.project.enabledModules.includes(Module.SURVEY)) {
          this.router.navigate(['/404']);
        }
      });
    this.createFilterForm();
  }

  async ngOnInit(): Promise<void> {
    this.load();
  }

  ngOnDestroy() {
    this.projectSubscription.unsubscribe();
  }

  getStatusClass(survey: Survey): string {
    switch (survey.status) {
      case SurveyStatus.ACTIVE:
        return 'green';
      case SurveyStatus.INACTIVE:
        return 'orange';
    }
  }

  changeSortOrder(event) {
    this.orderBy = event.target.value;
  }

  createFilterForm() {
    this.filterForm = this.formBuilder.group({
      search: [''],
      filter: [[SurveyStatus.ACTIVE, SurveyStatus.INACTIVE]],
      order: ['-createdAt'],
    });
  }

  private async load() {
    this.surveys = await this.surveyService.listByProject(this.project);
  }

  async deleteSurvey(survey: Survey): Promise<void> {
    await this.surveyService.remove(survey);
    this.load();
  }
}
