import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  NgForm,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Project } from 'src/app/interfaces/project';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { DetailGeneralContactComponent } from './detail-general/detail-general-contact.component';
import { ProjectService } from 'src/app/services/project.service';
import { FormGroupComponent } from 'src/app/components/form-group.component';
import { ErrorService } from 'src/app/services/error.service';
import { AccessDirective } from 'src/app/directives/access.directive';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { LoaderComponent } from 'src/app/components/loader.component';
import { LoadingDirective } from 'src/app/directives/loading.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { VersionDirective } from 'src/app/directives/version.directive';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';
import { StickySubmitComponent } from 'src/app/components/sticky-submit/sticky-submit.component';

@Component({
  selector: 'app-default-projects-detail-contact',
  templateUrl: 'detail-contact.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    NgClass,
    NgIf,
    NgForOf,
    LoaderComponent,
    LoadingDirective,
    DetailGeneralContactComponent,
    ReactiveFormsModule,
    AccessDirective,
    InlineSVGModule,
    VersionDirective,
    FormChangeDetectorDirective,
    FormGroupComponent,
    StickySubmitComponent,
  ],
})
export class DetailContactComponent implements OnInit {
  public notificationForm: FormGroup;
  public notificationLoading: boolean = false;
  public project: Project;
  @ViewChild('formRef') formRef: NgForm;
  @ViewChild(DetailGeneralContactComponent)
  contactComponent: DetailGeneralContactComponent;

  public constructor(
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private projectService: ProjectService,
    private projectDataBusService: ProjectDataBusService,
  ) {}

  async ngOnInit() {
    this.notificationForm = this.formBuilder.group({
      ticketContactEmails: this.formBuilder.array([]),
    });

    this.projectDataBusService.projectObservable.subscribe((project) => {
      this.project = project;
      const ticketContactEmails = this.notificationForm.get(
        'ticketContactEmails',
      ) as FormArray;
      ticketContactEmails.clear();
      if (this.project.ticketContactEmails) {
        this.project.ticketContactEmails.forEach(
          (ticketContactEmail: string) => {
            ticketContactEmails.push(
              this.addNotificationMail(ticketContactEmail),
            );
          },
        );
      }
    });
  }

  async formSaved() {
    this.projectDataBusService.emitProject(
      await this.projectService.fetch(this.project.slug),
    );
  }

  saveContact() {
    this.contactComponent.saveContact();
    this.saveNotifications();
  }

  deleteContactEmail(index: number): void {
    (this.notificationForm.get('ticketContactEmails') as FormArray).removeAt(
      index,
    );
  }

  public async saveNotifications() {
    this.formRef.ngSubmit.emit();
    this.errorService.markFormGroupTouchedAndDirty(this.notificationForm);

    if (!this.notificationForm.valid) {
      return;
    }

    const data = this.notificationForm.getRawValue();
    this.notificationLoading = true;

    try {
      data.ticketContactEmails = data.ticketContactEmails
        .map((data: any) => {
          return data.ticketContactEmail;
        })
        .filter((data: any) => {
          return !!data;
        });

      await this.projectService.updateSettings(this.project, data);
    } catch (error) {
      this.errorService.parseErrorsToForm(this.notificationForm, error.error);
    } finally {
      this.notificationLoading = false;
    }
  }

  addNotificationMail(email?: string): FormGroup {
    return this.formBuilder.group({
      ticketContactEmail: [email, Validators.email],
    });
  }

  addEmptyNotificationMail(): void {
    (this.notificationForm.get('ticketContactEmails') as FormArray).push(
      this.addNotificationMail(),
    );
  }
}
