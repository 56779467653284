<div class="bouwapp-box__self__children" *appVersion="1">
  <div class="bouwapp-box__self__children__head">
    <p>{{ 'projects.detail.documents.title' | translate }}</p>
    <a
      appAccess
      role="manager"
      (click)="openCategoryCreate()"
      [project]="project"
    >
      {{ 'projects.detail.documents.create' | translate }}
    </a>
  </div>

  <app-loader *ngIf="loading"></app-loader>

  <p class="no-results" *ngIf="documentCategories.length == 0 && !loading">
    {{ 'projects.detail.documents.empty' | translate }}
  </p>

  <ul
    [dragula]="Sortable.CATEGORIES"
    (dragulaModelChange)="updateCategorySortOrder($event)"
    [(dragulaModel)]="documentCategories"
    *ngIf="documentCategories.length"
  >
    <li *ngFor="let category of documentCategories" class="project-category">
      <div class="project-category__default">
        <span
          [inlineSVG]="'/assets/img/icons/handle.svg'"
          class="draggable-parent"
          *ngIf="canEdit"
        ></span>
        <div class="group">
          <p>{{ category.title }}</p>
          <div>
            <span
              class="no-results"
              *ngIf="
                !documents[category.id] || documents[category.id]?.length === 0
              "
            >
              {{ 'projects.detail.documents.documents.empty' | translate }}
            </span>
            <span
              class="results"
              *ngIf="documents[category.id]?.length"
              [innerHTML]="
                'projects.detail.documents.count'
                  | translate: { count: documents[category.id]?.length }
              "
            >
            </span>
            <ng-container
              *ngIf="documents[category.id]?.length < MAX_DOCUMENTS"
            >
              <a
                href="javascript:void(0);"
                appAccess
                role="manager"
                [project]="project"
                (click)="openDocumentCreate(category)"
              >
                {{ 'projects.detail.documents.documents.new' | translate }}
              </a>
            </ng-container>
          </div>
        </div>
        <div class="manage">
          <div
            (click)="openCategoryEdit(category)"
            appAccess
            role="manager"
            [project]="project"
          >
            {{ 'projects.detail.documents.documents.change' | translate }}
          </div>
          <div
            (click)="confirmDeleteCategory.confirmDelete(category)"
            appAccess
            role="manager"
            [project]="project"
          >
            {{ 'projects.detail.documents.documents.remove' | translate }}
          </div>
        </div>
      </div>

      <div
        class="project-category__documents"
        [hidden]="
          !documents[category.id] || documents[category.id].length === 0
        "
      >
        <p>{{ 'projects.detail.general.documents.title' | translate }}</p>
        <ul
          [attr.category-id]="category.id"
          [dragula]="Sortable.DOCUMENTS"
          (dragulaModelChange)="updateDocumentSortOrder($event, category)"
          [(dragulaModel)]="documents[category.id]"
        >
          <li *ngFor="let document of documents[category.id]" class="document">
            <div class="document__image">
              <img
                width="54"
                height="54"
                *ngIf="!isVideo(document)"
                [src]="getAttachmentImage(document)"
                alt="{{ document.title }}"
              />
              <i
                class="spinner"
                *ngIf="isVideo(document) && document.isConverted != 1"
              ></i>
              <video
                *ngIf="isVideo(document) && document.isConverted == 1"
                controls
              >
                <source [src]="getAttachmentImage(document)" />
              </video>
            </div>
            <div>
              <p>{{ document.title }}</p>
              <div appAccess role="manager" [project]="project">
                <div
                  (click)="confirmDelete.confirmDelete(document)"
                  [attr.aria-label]="
                    'projects.detail.documents.documents.remove' | translate
                  "
                >
                  {{ 'projects.detail.documents.documents.remove' | translate }}
                </div>
                <a
                  (click)="downloadDocument(document)"
                  [attr.aria-label]="
                    'projects.detail.documents.documents.download' | translate
                  "
                  role="link"
                  download
                >
                  @if (isYoutube(document)) {
                    {{ 'project.detail.tickets.status.open' | translate }}
                  } @else {
                    {{ 'profile.export.download' | translate }}
                  }
                </a>
              </div>
            </div>
            <span
              [inlineSVG]="'/assets/img/icons/handle.svg'"
              class="draggable-child"
              *ngIf="canEdit"
            ></span>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>

<ng-container *appVersion="2">
  <div class="bouwapp-header standalone">
    <div class="bouwapp-header__text">
      <h1>
        {{ 'projects.detail.settings.title.prefix' | translate }}
        -
        {{ 'project.detail.sections.title' | translate }}
      </h1>
    </div>
  </div>

  <div class="bouwapp-box full">
    <div class="bouwapp-box__title">
      <h2>
        {{ 'project.detail.sections.title' | translate }}
      </h2>
    </div>
    <div class="bouwapp-box__self">
      <div class="bouwapp-box__subheader" [class.border]="sections.length">
        <p
          [innerHTML]="'projects.detail.general.documents.subtitle' | translate"
        ></p>
        <div class="section-controls">
          <button
            (click)="addCategory()"
            class="button--black-white"
            appAccess
            role="manager"
            [project]="project"
          >
            <span inlineSVG="/assets/v2/img/icons/plus.svg"></span>
            <p>
              {{ 'project.detail.sections.create' | translate }}
            </p>
          </button>
          <span>{{
            'project.detail.sections.create.predefined' | translate
          }}</span>
          <div class="default-sections">
            <button
              (click)="addCategory(section)"
              class="button button--m button--outlined bg--grey--hover"
              *ngFor="let section of defaultSections"
            >
              {{ 'project.detail.sections.default.' + section | translate }}
            </button>
          </div>
        </div>
      </div>

      <app-loader *ngIf="!sections.length && loading"></app-loader>

      <div class="bouwapp-box__self__children">
        <form
          (ngSubmit)="saveChanges()"
          [formGroup]="sectionsForm"
          #form="ngForm"
        >
          <ul
            [dragula]="Sortable.CATEGORIES"
            (dragulaModelChange)="updateCategorySortOrder($event)"
            [(dragulaModel)]="sections.controls"
            *ngIf="sections.length"
            formArrayName="sections"
          >
            <li
              *ngFor="let section of sections.controls; let i = index"
              [formGroupName]="i"
              class="project-category"
            >
              <div class="project-category__default">
                <div class="index">
                  <span>{{ i + 1 }}</span>
                </div>
                <app-form-group errorPath="title" [form]="sections.controls[i]">
                  <label>
                    {{
                      'projects.detail.documents.categories.edit.title'
                        | translate
                    }}
                  </label>
                  <input
                    type="text"
                    class="input input--m"
                    formControlName="title"
                  />
                </app-form-group>
                <app-form-group
                  [form]="sections.controls[i]"
                  errorPath="content"
                >
                  <label>
                    {{
                      'projects.detail.documents.categories.edit.content'
                        | translate
                    }}
                  </label>
                  <app-quill
                    [formGroup]="sections.controls[i]"
                    controlName="content"
                    placeholder="{{
                      'projects.detail.documents.categories.edit.placeholder'
                        | translate
                    }}"
                  ></app-quill>
                </app-form-group>
                <div class="project-category__documents form-group">
                  <label>{{
                    'projects.detail.general.documents.title' | translate
                  }}</label>
                  <ng-container
                    *ngIf="documents[section.value.id]?.length < MAX_DOCUMENTS"
                  >
                    <a
                      href="javascript:void(0);"
                      appAccess
                      role="manager"
                      [project]="project"
                      (click)="openDocumentCreate(section.value)"
                    >
                      {{
                        'projects.detail.documents.documents.add' | translate
                      }}
                    </a>
                  </ng-container>
                  <ul
                    [attr.category-id]="section.value.id"
                    [dragula]="Sortable.DOCUMENTS"
                    (dragulaModelChange)="
                      updateDocumentSortOrder($event, section.value)
                    "
                    [(dragulaModel)]="documents[section.value.id]"
                  >
                    <li
                      *ngFor="let document of documents[section.value.id]"
                      class="document"
                    >
                      <div>
                        <div class="document__image">
                          <img
                            width="54"
                            height="54"
                            *ngIf="!isVideo(document)"
                            [src]="getAttachmentImage(document)"
                            alt="{{ document.title }}"
                          />
                          <i
                            class="spinner"
                            *ngIf="
                              isVideo(document) && document.isConverted != 1
                            "
                          ></i>
                          <video
                            *ngIf="
                              isVideo(document) && document.isConverted == 1
                            "
                            controls
                          >
                            <source [src]="getAttachmentImage(document)" />
                          </video>
                        </div>
                        <div class="document__buttons">
                          <p>{{ document.title }}</p>
                          <div appAccess role="manager" [project]="project">
                            <a
                              [attr.aria-label]="
                                'projects.detail.documents.documents.remove'
                                  | translate
                              "
                              role="link"
                              class="document__buttons__delete"
                              (click)="confirmDelete.confirmDelete(document)"
                            >
                              {{
                                'projects.detail.documents.documents.remove'
                                  | translate
                              }}
                            </a>
                            <a
                              (click)="downloadDocument(document)"
                              [attr.aria-label]="
                                'projects.detail.documents.documents.download'
                                  | translate
                              "
                              role="link"
                              class="document__buttons__download"
                              download
                            >
                              @if (isYoutube(document)) {
                                {{
                                  'project.detail.tickets.status.open'
                                    | translate
                                }}
                              } @else {
                                {{ 'profile.export.download' | translate }}
                              }
                            </a>
                          </div>
                        </div>
                      </div>
                      <span
                        [inlineSVG]="'/assets/img/icons/handle.svg'"
                        class="draggable-child"
                        *ngIf="canEdit"
                      ></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="project-category__manage">
                <span
                  inlineSVG="/assets/img/icons/handle.svg"
                  class="draggable-parent"
                  *ngIf="canEdit"
                ></span>
                <span
                  (click)="confirmDeleteCategory.confirmDelete(section.value)"
                  appAccess
                  role="manager"
                  [project]="project"
                  inlineSVG="/assets/img/icons/trash.svg"
                ></span>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</ng-container>

<section class="projects documents create">
  <app-modal
    [title]="
      (editingDocument
        ? 'projects.detail.documents.documents.edit.edit'
        : 'projects.detail.documents.documents.edit.create'
      ) | translate
    "
    #editDocument
    (onClose)="resetDocument()"
  >
    <form
      appFormChangeDetector
      [formGroup]="documentForm"
      (submit)="saveDocument()"
    >
      <app-form-group errorPath="title" [form]="documentForm">
        <label>{{
          'projects.detail.documents.documents.edit.title' | translate
        }}</label>
        <input class="input input--m" type="text" formControlName="title" />
      </app-form-group>
      <app-form-group errorPath="filePath" [form]="documentForm">
        <app-input-file
          class="input-file-holder input-file-holder-modal"
          type="project-document-document"
          [uploadPdfs]="true"
          [uploadImages]="true"
          [rotateWarning]="''"
          [chooseVideo]="true"
          (videoChosen)="updateVideo($event)"
          [withCustomVideo]="true"
          (startUploading)="startUploading()"
          (stopUploading)="stopUploading()"
          (previewUpdated)="updatePreview($event)"
          formControlName="filePath"
        >
        </app-input-file>
      </app-form-group>
      <app-input-file-preview
        #preview
        [videoId]="documentForm.get('videoId').value"
        [thumbnails]="documentForm.get('filePathThumbnails').value"
        [videoPreview]=""
        [deleteText]="
          'projects.detail.documents.documents.document.remove' | translate
        "
        (cleared)="clearVideo()"
        formControlName="filePath"
      ></app-input-file-preview>
      <div class="form-group">
        <button
          type="submit"
          class="btn btn-primary"
          [appLoading]="formLoading || uploading"
        >
          {{ 'projects.detail.documents.documents.edit.save' | translate }}
        </button>
      </div>
    </form>
  </app-modal>
</section>

<section class="projects category create">
  <app-modal
    [title]="
      (editingCategory
        ? 'projects.detail.documents.categories.edit.edit'
        : 'projects.detail.documents.categories.edit.create'
      ) | translate
    "
    #editCategory
    [scroll]="false"
    (onClose)="resetCategory()"
  >
    <form
      appFormChangeDetector
      [formGroup]="categoryForm"
      (submit)="saveCategory()"
    >
      <app-form-group errorPath="title" [form]="categoryForm">
        <label>{{
          'projects.detail.documents.categories.edit.title' | translate
        }}</label>
        <input type="text" class="input input--m" formControlName="title" />
      </app-form-group>

      <app-form-group [form]="documentForm" errorPath="content">
        <label>{{
          'projects.detail.documents.categories.edit.content' | translate
        }}</label>
        <app-quill
          [formGroup]="categoryForm"
          controlName="content"
          placeholder="{{
            'projects.detail.documents.categories.edit.placeholder' | translate
          }}"
        ></app-quill>
      </app-form-group>
      <br />

      <div class="form-group">
        <button
          type="submit"
          class="btn btn-primary"
          [appLoading]="formLoading"
        >
          {{ 'projects.detail.documents.categories.edit.save' | translate }}
        </button>
      </div>
    </form>
  </app-modal>
</section>

<app-confirm-delete
  #confirmDelete
  (confirmed)="deleteProjectDocument($event)"
></app-confirm-delete>
<app-confirm-delete
  #confirmDeleteCategory
  (confirmed)="deleteCategory($event)"
></app-confirm-delete>
