import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { Project } from '../../../interfaces/project';
import { ProjectDataBusService } from '../../../services/project-data-bus.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import { WidgetOption } from 'src/app/enums/widget-option';
import { NgIf } from '@angular/common';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';
import { VersionDirective } from 'src/app/directives/version.directive';

@Component({
  selector: 'app-default-projects-detail-widget',
  templateUrl: './detail-widget.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormChangeDetectorDirective,
    ClipboardModule,
    NgIf,
    TranslateModule,
    VersionDirective,
  ],
})
export class DetailWidgetComponent {
  @ViewChild('codeToCopy') codeToCopy: ElementRef;

  widgetForm: FormGroup;
  embedUrl: string;
  iframeUrl: SafeUrl;
  copied: boolean = false;
  WidgetOption = WidgetOption;
  public formLoading = false;

  public project: Project;

  constructor(
    private fb: FormBuilder,
    private projectDataBusService: ProjectDataBusService,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService
  ) {
    this.projectDataBusService.projectObservable.subscribe(
      (project) => (this.project = project)
    );
    this.createForm();
    this.generateUrl();
  }

  generateUrl() {
    if (this.widgetForm.valid) {
      let url =
        `https://${this.project.pwaDomain}/embed/${this.project.slug}` +
        `?header=${this.widgetForm.get('header').value}` +
        `&theme=${this.widgetForm.get('theme').value}` +
        `&info=${this.widgetForm.get('info').value}` +
        `&v=${Date.now()}`;
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.embedUrl =
        `<iframe width="${this.widgetForm.get('width').value}" ` +
        `height="${this.widgetForm.get('height').value}" ` +
        `src="${url}" ` +
        `title="${this.translateService.instant(
          'projects.detail.widget.iframe.title'
        )}" ` +
        `frameborder="0" allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture" ` +
        `allowfullscreen></iframe>`;
    }
  }

  textCopied() {
    this.copied = true;
    setTimeout(() => (this.copied = false), 2000);
  }

  private createForm() {
    this.widgetForm = this.fb.group({
      header: [WidgetOption.SHOW, Validators.required],
      theme: [WidgetOption.LIGHT, Validators.required],
      info: [WidgetOption.SHOW, Validators.required],
      width: [375, Validators.required],
      height: [667, Validators.required],
    });
  }
}
