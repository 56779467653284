@if (show) {
  <div class="sticky-submit">
    <span>
      <h3>
        {{ title() | translate }}
      </h3>
      <div>
        {{ 'sticky-submit.description' | translate }}
      </div>
    </span>
    <button
      [class]="buttonClass()"
      (click)="submit.emit()"
      [appLoading]="loading()"
    >
      {{ buttonText() | translate }}
    </button>
  </div>
}
