import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { DragulaService, DragulaModule } from 'ng2-dragula';
import { ModalComponent } from 'src/app/components/modal.component';
import { ProjectStatus } from 'src/app/enums/project-status';
import { Sortable } from 'src/app/enums/sortable';
import { Project } from 'src/app/interfaces/project';
import { Target } from 'src/app/interfaces/target';
import { SecurityVoter } from 'src/app/security/security-voter';
import { AccessService } from 'src/app/services/access.service';
import { ErrorService } from 'src/app/services/error.service';
import { ProjectService } from 'src/app/services/project.service';
import { TargetService } from 'src/app/services/target.service';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDeleteComponent } from '../../../../components/confirm-delete.component';
import { SuccessMessageComponent } from '../../../../components/success-message.component';
import { LoadingDirective } from '../../../../directives/loading.directive';
import { FormGroupComponent } from '../../../../components/form-group.component';
import { ModalComponent as ModalComponent_1 } from '../../../../components/modal.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LoaderComponent } from '../../../../components/loader.component';
import { AccessDirective } from '../../../../directives/access.directive';
import { NgIf, NgFor } from '@angular/common';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';
import { VersionDirective } from 'src/app/directives/version.directive';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { OfflineOperation } from 'src/app/interfaces/offline-operation';

@Component({
  selector: 'app-detail-general-targets',
  templateUrl: './detail-general-targets.component.html',
  styles: [],
  standalone: true,
  imports: [
    NgIf,
    AccessDirective,
    LoaderComponent,
    DragulaModule,
    NgFor,
    VersionDirective,
    InlineSVGModule,
    ModalComponent_1,
    ReactiveFormsModule,
    FormGroupComponent,
    LoadingDirective,
    SuccessMessageComponent,
    ConfirmDeleteComponent,
    TranslateModule,
    FormChangeDetectorDirective,
  ],
})
export class DetailGeneralTargetsComponent implements OnInit {
  @ViewChild('edit', { static: true }) private editModal: ModalComponent;
  @Input() step: number;
  project: Project;
  targetForm: FormGroup;
  targets: Target[] = [];
  Sortable = Sortable;
  formLoading = false;
  editingTarget: Target | null = null;
  targetSaved = false;
  canEdit: boolean = false;
  ProjectStatus = ProjectStatus;
  private offlineOperationsQueue: OfflineOperation[] = [];
  private isConcept: boolean = false;
  private sort: any;

  constructor(
    private targetService: TargetService,
    private errorService: ErrorService,
    private fb: FormBuilder,
    private dragulaService: DragulaService,
    private element: ElementRef,
    private projectService: ProjectService,
    private accessService: AccessService,
    private projectDataBusService: ProjectDataBusService,
  ) {
    this.createForm();

    this.dragulaService.createGroup(Sortable.TARGETS, {
      moves: (el, container, handle) => {
        return (
          handle.classList.contains('draggable') ||
          handle.parentElement.classList.contains('draggable') ||
          handle.parentElement.parentElement.classList.contains('draggable')
        );
      },
      mirrorContainer: this.element.nativeElement,
    });

    this.projectDataBusService.projectObservable.subscribe((project) => {
      if (project) {
        this.project = project;
        if (this.project.slug === '') {
          this.isConcept = true;
        }
        if (this.project.id) {
          this.flushQueue();
        }
      }
    });
  }

  ngOnInit(): void {
    this.targetService.updated$.subscribe((item) => {
      this.load();
    });

    if (this.project.create !== true) {
      this.accessService.accessControlList.subscribe(
        (acl) =>
          (this.canEdit = SecurityVoter.canEditProject(acl, this.project)),
      );
    } else {
      this.accessService.accessControlList.subscribe(
        (acl) =>
          (this.canEdit =
            SecurityVoter.hasCreateRole(acl) ||
            SecurityVoter.hasCustomers(acl)),
      );
    }
  }

  updateTargetSortOrder(event) {
    let data = {};
    event.forEach((category, index) => {
      data[category.id] = index;
    });
    if (this.isConcept) {
      this.sort = data;
    } else {
      this.projectService.updateTargetSortOrder(this.project.slug, data);
    }
  }

  public async flushQueue() {
    while (this.offlineOperationsQueue.length > 0) {
      const { operation, target } = this.offlineOperationsQueue.shift()!;
      try {
        await this.executeOperation(operation, target);
      } catch (error) {
        this.offlineOperationsQueue.unshift({ operation, target });
      }
    }
    if (this.sort) {
      this.projectService.updateTargetSortOrder(this.project.slug, this.sort);
    }
  }

  private async executeOperation(operation: string, target: Target) {
    switch (operation) {
      case 'create':
        target.project = this.project;
        await this.targetService.create(target);
        break;
      case 'delete':
        await this.targetService.deleteGlobal(target, this.project);
        break;
      default:
        throw new Error(`Unknown operation: ${operation}`);
    }
  }

  async saveTarget() {
    this.errorService.markFormGroupTouchedAndDirty(this.targetForm);

    if (!this.targetForm.valid) {
      return;
    }
    this.targetSaved = false;
    this.formLoading = true;

    try {
      const data = this.targetForm.value as Target;

      if (this.editingTarget != null) {
        if (this.isConcept) {
          const index = this.targets.findIndex(
            (target) => target.id === this.editingTarget.id,
          );

          if (index !== -1) {
            this.targets[index].title = data.title;
            this.addToQueue({
              operation: 'update',
              target: this.targets[index],
            });
          }
        } else {
          await this.targetService.update(this.editingTarget.slug, data);
        }

        this.editingTarget.title = data.title;
      } else {
        data.project = this.project;
        if (this.isConcept) {
          const target = {
            id: this.getNextId(),
            isGlobal: false,
            title: data.title,
            project: data.project,
          } as Target;
          this.targets.unshift(target);
          this.addToQueue({ operation: 'create', target: target });
        } else {
          await this.targetService.create(data);
        }
      }

      //Set timeout here is for the change detection on
      //app-success-message have time to detect this again
      setTimeout(() => {
        this.targetSaved = true;
      }, 0);

      this.editingTarget = null;
      this.editModal.close();
    } catch (error) {
      this.errorService.parseErrorsToForm(this.targetForm, error.error);
    } finally {
      this.formLoading = false;
    }
  }

  async deleteTarget(target: Target) {
    this.targets = this.targets.filter((t) => t.id !== target.id);
    this.targetSaved = false;

    if (target.isGlobal) {
      if (this.isConcept) {
        this.addToQueue({ operation: 'delete', target });
      } else {
        await this.targetService.deleteGlobal(target, this.project);
      }
    } else {
      if (this.isConcept) {
        this.addToQueue({ operation: 'delete', target });
      } else {
        await this.targetService.delete(target);
      }
    }

    setTimeout(() => {
      this.targetSaved = true;
    }, 0);
  }

  private addToQueue(operation: OfflineOperation) {
    const existingOperationIndex = this.offlineOperationsQueue.findIndex(
      (op) => op.target.id === operation.target.id,
    );
    if (existingOperationIndex !== -1) {
      const existingOperation =
        this.offlineOperationsQueue[existingOperationIndex];
      if (
        operation.operation === 'update' &&
        existingOperation.operation === 'create'
      ) {
        this.offlineOperationsQueue[existingOperationIndex].target =
          operation.target;
        return;
      }
      if (
        operation.operation === 'delete' &&
        existingOperation.operation === 'create'
      ) {
        this.offlineOperationsQueue.splice(existingOperationIndex, 1);
        return;
      }
    }

    this.offlineOperationsQueue.push(operation);
  }

  ngOnDestroy() {
    this.dragulaService.destroy(Sortable.TARGETS);
  }

  private getNextId(): number {
    const ids = this.targets.map((target) => target.id);

    const maxId = ids.length > 0 ? Math.max(...ids) : 0;

    return maxId + 1;
  }

  createForm() {
    this.targetForm = this.fb.group({
      title: ['', Validators.required],
    });
  }

  openCreate() {
    this.editModal.open();
  }

  openEdit(target: Target) {
    this.editingTarget = target;
    this.targetForm.patchValue(target);
    this.editModal.open();
  }

  reset() {
    this.targetForm.reset();
    this.editingTarget = null;
  }

  private load() {
    this.targetService.listAll(this.project).subscribe((items) => {
      this.targets = items;
    });
  }
}