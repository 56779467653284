import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ErrorService } from 'src/app/services/error.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { LocaleService } from 'src/app/services/locale.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProjectUser } from 'src/app/interfaces/project-user';
import { ProjectUserService } from 'src/app/services/project-user.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { TwoFactorService } from 'src/app/services/two-factor.service';
import {
  AngularIntlPhoneComponent,
  AngularIntlPhoneConfig,
  AngularIntlPhoneModule,
} from 'angular-intl-phone';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from '../../../components/footer.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { FormGroupComponent } from '../../../components/form-group.component';
import { LoaderComponent } from '../../../components/loader.component';
import { NgIf } from '@angular/common';
import { platform } from '../../../services/platform.service';
import { ThemeService } from 'src/app/services/theme.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { TwoFactorMethodSwitcher } from '../../../components/two-factor-method-switcher/two-factor-method-switcher.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html',
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate('0.5s ease-in')]),
    ]),
  ],
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    ReactiveFormsModule,
    FormGroupComponent,
    AngularIntlPhoneModule,
    LoadingDirective,
    NgSelectModule,
    FooterComponent,
    TranslateModule,
    TwoFactorMethodSwitcher,
  ],
})
export class TwoFactorComponent implements OnInit {
  @ViewChild(AngularIntlPhoneComponent, { static: false })
  phoneComponent: AngularIntlPhoneComponent;

  private oldCountryCode: string;

  public error = false;
  public form: FormGroup;
  public twoFaForm: FormGroup;
  public loading: boolean = true;
  public step: number = 0;
  public logo: SafeResourceUrl;
  public sended: boolean = false;
  public invalidCode: boolean = false;
  public resendSuccess: boolean = false;
  public loginCounter: number = 0;
  public method: string = 'email';
  public needMethod: boolean = false;

  phoneConfig: AngularIntlPhoneConfig = {
    id: 'phoneNumber',
    name: 'phoneNumber',
    options: {
      separateDialCode: true,
      localizedCountries: {},
      onlyCountries: ['au', 'cz', 'dk', 'de', 'gb', 'nl', 'sk', 'be'],
      preferredCountries: [],
    },
  };

  /**
   * @type {ProjectUser}
   */
  public projectUser: ProjectUser;

  constructor(
    private sanitizer: DomSanitizer,
    private projectUserService: ProjectUserService,
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private localeService: LocaleService,
    private twoFactorService: TwoFactorService,
    private authService: AuthService,
    private userDataService: UserDataService,
    private translateService: TranslateService,
    private themeSerivce: ThemeService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(platform.logo);

    if (localeService.localizedCountryNames) {
      this.phoneConfig.options.localizedCountries =
        localeService.localizedCountryNames;
    }

    this.translateService
      .get('two.factor.phone.placeholder')
      .subscribe((result) => {
        this.phoneConfig.placeholder = result;
      });
    this.phoneConfig.options.initialCountry =
      platform.language == 'nl' ? 'nl' : 'gb';
  }

  public async handleMethodChange(formData: any) {
    this.method = formData.method;
    if (formData.save) {
      this.projectUser.twoFactorMethod = this.method;
    }
    if (this.method === 'sms' && !this.projectUser.phoneNumber) {
      this.step = 1;
      return;
    }
    await this.twoFactorService.send(this.method);
    this.projectUser.twoFactorEnabled = false;
    this.userDataService.storeProjectUser(this.projectUser);
    this.step = 2;
  }

  async loadProjectUser(): Promise<void> {
    const projectUser: ProjectUser =
      await this.userDataService.retrieveProjectUser();

    try {
      this.projectUser = await this.projectUserService.fetch(projectUser.id);
    } catch (e) {
      this.authService.login();
    }
  }

  async ngOnInit() {
    const version = await this.themeSerivce.getVersion();
    if (version === 2) {
      this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(
        platform.logo.replace('.png', '_beta.png'),
      );
    }

    await this.loadProjectUser();

    if (this.projectUser) {
      this.form = this.formBuilder.group({
        phoneNumber: ['', [Validators.required]],
      });

      if (this.projectUser.phoneNumber) {
        const phoneNumber = '+' + this.projectUser.phoneNumber;
        this.form.get('phoneNumber').setValue(phoneNumber);
      }

      this.form.get('phoneNumber').valueChanges.subscribe((value: any) => {
        if (value?.dialCodeWithPlus) {
          if (value.dialCodeWithPlus !== this.oldCountryCode) {
            if (value.e164Number.startsWith(this.oldCountryCode)) {
              const cleanNumber = value.e164Number
                .substring(this.oldCountryCode.length)
                .trim();
              this.phoneComponent.writeValue(cleanNumber);
            }
          }
          this.oldCountryCode = value.dialCodeWithPlus;
        }
        const phoneNumber = this.form.get('phoneNumber');
        if (phoneNumber?.errors?.invalid) {
          phoneNumber.setErrors({
            phoneNumber: true,
          });
        }
      });
      const param = history.state.method;
      if (param) {
        this.step = 1;
        this.method = 'sms';
        this.needMethod = true;
      } else {
        const method = this.projectUser.twoFactorMethod;
        if (method) {
          this.method = method;
          if (this.twoFactorService.getForced()) {
            this.step = 0;
          } else if (method === 'sms' && !this.projectUser.phoneNumber) {
            this.step = 1;
          } else {
            await this.twoFactorService.send(this.method);
            this.step = 2;
          }
        }
      }
    }

    const pattern = /^\d+$/;
    this.twoFaForm = this.formBuilder.group({
      code: [
        '',
        [
          Validators.required,
          Validators.maxLength(6),
          Validators.pattern(pattern),
        ],
      ],
    });
    this.loading = false;
  }

  getResendMethod(): string {
    return this.method === 'sms' ? 'email' : 'sms';
  }

  async resend(method?: string) {
    const finalMethod = method ?? this.method;

    this.sended = true;
    this.twoFactorService
      .resend(finalMethod)
      .then((res) => {
        this.loginCounter = 0;
        this.sended = false;
        this.error = false;
        this.resendSuccess = true;
        this.invalidCode = false;
      })
      .catch((err) => {
        this.invalidCode = false;
        this.error = true;
        this.sended = false;
        this.resendSuccess = false;
      });
  }

  public async submit() {
    if (this.step === 1 && this.form.valid) {
      this.loading = true;
      const data = this.form.getRawValue();
      if (data.phoneNumber !== undefined) {
        this.projectUser.phoneNumber =
          data.phoneNumber.e164Number?.replace(/\+/g, '') ?? data.phoneNumber;
      }

      this.projectUser.twoFactorEnabled = false;
      const projectUser: ProjectUser = await this.projectUserService.update(
        this.projectUser,
      );
      await this.userDataService.storeProjectUser(projectUser);
      await this.twoFactorService.send(this.method);
      this.step = 2;
      this.loading = false;
    }

    if (this.step === 2) {
      if (this.twoFaForm.valid) {
        const verificationCode = this.twoFaForm.value.code;
        try {
          this.sended = true;
          this.loginCounter = await this.twoFactorService.validate(
            Number(verificationCode),
          );
          this.projectUser.twoFactorEnabled = true;

          const projectUser: ProjectUser = await this.projectUserService.update(
            this.projectUser,
          );
          await this.userDataService.storeProjectUser(projectUser);
          await this.router.navigate(['']);
        } catch (e) {
          this.invalidCode = true;
          this.resendSuccess = false;
          this.error = false;
          this.sended = false;
          if (this.loginCounter < 3) {
            this.loginCounter++;
          } else {
            window.location.reload();
          }
        }
      }
      if (this.twoFaForm.invalid && this.twoFaForm.touched) {
        this.errorService.markFormGroupTouchedAndDirty(this.twoFaForm);
      }
    }
  }
}
