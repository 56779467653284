<div class="bouwapp-header">
  <div class="bouwapp-header__text">
    <h1>
      <ng-container *appVersion="2">
        {{ 'projects.detail.settings.title.prefix' | translate }}
        -
      </ng-container>
      {{ 'project.detail.livestreams.title' | translate }}
    </h1>
  </div>
  <div class="bouwapp-header__buttons" *appVersion="1">
    <button
      type="button"
      class="primary"
      (click)="openCreate()"
      appAccess
      role="manager"
      [project]="project"
    >
      <span class="icon" [inlineSVG]="'/assets/icons/add--white.svg'"></span>
      {{ 'project.detail.livestreams.new' | translate }}
    </button>
  </div>
</div>

<ng-container *appVersion="1">
  <section class="livestreams">
    <p [hidden]="!projectLivestreams">
      {{ 'project.detail.livestreams.approval' | translate }}
    </p>
    <div class="row">
      <div class="col-md-12">
        <br clear="both" />
        <app-loader *ngIf="dataLoading"></app-loader>
        <div
          class="account"
          *ngIf="!dataLoading"
          [hidden]="!projectLivestreams"
        >
          <ul class="livestreams__head">
            <li>{{ 'project.detail.livestreams.table.name' | translate }}</li>
            <li>{{ 'project.detail.livestreams.table.url' | translate }}</li>
          </ul>
          <table class="list">
            <tbody>
              <tr *ngFor="let livestream of projectLivestreams">
                <td
                  [attr.data-th]="
                    'project.detail.livestreams.table.name' | translate
                  "
                >
                  {{ livestream.name }}
                </td>
                <td
                  [attr.data-th]="
                    'project.detail.livestreams.table.url' | translate
                  "
                >
                  {{ livestream.url }}
                </td>
                <td
                  [attr.data-th]="
                    'project.detail.settings.users.table.actions' | translate
                  "
                >
                  <div appAccess role="manager" [project]="project">
                    <div
                      [inlineSVG]="'/assets/img/icons/edit-small.svg'"
                      (click)="openEdit(livestream)"
                      class="tile-action tile-edit inline-svg__item"
                    ></div>

                    <div
                      [inlineSVG]="'/assets/img/icons/delete.svg'"
                      (click)="
                        confirmDeleteLivestream.confirmDelete(livestream)
                      "
                      class="tile-action tile-delete inline-svg__item"
                    ></div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="projectLivestreams.length === 0">
                <td colspan="2">
                  {{ 'project.detail.livestreams.empty' | translate }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</ng-container>

<ng-container *appVersion="2">
  <div class="bouwapp-box full">
    <div class="bouwapp-box__title">
      <h2>
        {{ 'project.detail.livestreams.title' | translate }}
      </h2>
    </div>
    <div class="bouwapp-box__self">
      <div class="bouwapp-box__subheader">
        <p [innerHTML]="'project.detail.livestreams.approval' | translate"></p>
        <button
          (click)="openCreate()"
          class="button--black-white"
          appAccess
          role="manager"
          [project]="project"
        >
          <span inlineSVG="/assets/v2/img/icons/plus.svg"></span>
          <p>
            {{ 'project.detail.livestreams.new.v2' | translate }}
          </p>
        </button>
      </div>
      <app-loader *ngIf="dataLoading"></app-loader>
      <section class="livestreams" *ngIf="projectLivestreams.length">
        <div class="row">
          <div class="col-md-12">
            <div>
              <ul class="livestreams__head">
                <li>
                  {{ 'project.detail.livestreams.table.status' | translate }}
                </li>
                <li>
                  {{ 'project.detail.livestreams.table.name' | translate }}
                </li>
                <li>
                  {{ 'project.detail.livestreams.table.url' | translate }}
                </li>
                <li>
                  {{
                    'project.detail.livestreams.table.created_on' | translate
                  }}
                </li>
                <li>
                  {{
                    'project.detail.livestreams.table.created_by' | translate
                  }}
                </li>
              </ul>
              <table class="list">
                <tbody>
                  <tr *ngFor="let livestream of projectLivestreams">
                    <td
                      [attr.data-th]="
                        'project.detail.livestreams.table.status' | translate
                      "
                    >
                      <span
                        class="livestream-status"
                        [class.active]="livestream.approved"
                      >
                        {{
                          (livestream.approved
                            ? 'project.detail.livestreams.active'
                            : 'project.detail.livestreams.inactive'
                          ) | translate
                        }}
                      </span>
                    </td>
                    <td
                      [attr.data-th]="
                        'project.detail.livestreams.table.name' | translate
                      "
                    >
                      {{ livestream.name }}
                    </td>
                    <td
                      [attr.data-th]="
                        'project.detail.livestreams.table.url' | translate
                      "
                    >
                      {{ livestream.url }}
                    </td>
                    <td
                      [attr.data-th]="
                        'project.detail.livestreams.table.created_on'
                          | translate
                      "
                    >
                      {{ livestream.createdAt | date : 'mediumDate' }}
                      <span>{{
                        livestream.createdAt | date : 'shortTime'
                      }}</span>
                    </td>
                    <td
                      [attr.data-th]="
                        'project.detail.livestreams.table.created_by'
                          | translate
                      "
                    >
                      @if(livestream.createdBy){
                      {{
                        livestream.createdBy?.firstName +
                          ' ' +
                          livestream.createdBy?.lastName
                      }}
                      }
                    </td>
                    <td
                      [attr.data-th]="
                        'project.detail.settings.users.table.actions'
                          | translate
                      "
                    >
                      <div appAccess role="manager" [project]="project">
                        <div
                          (click)="
                            confirmDeleteLivestream.confirmDelete(livestream)
                          "
                          class="tile-action tile-delete"
                        >
                          {{ 'project.detail.livestreams.delete' | translate }}
                        </div>
                        <div
                          (click)="openEdit(livestream)"
                          class="tile-action tile-edit"
                        >
                          {{ 'project.detail.livestreams.edit' | translate }}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</ng-container>

<section class="projects create">
  <app-modal
    [title]="
      (editingLivestream
        ? 'project.detail.livestreams.edit.edit'
        : 'project.detail.livestreams.edit.create'
      ) | translate
    "
    #edit
    class="livestreams__modal"
    (onClose)="reset()"
  >
    <form appFormChangeDetector [formGroup]="livestreamForm">
      <app-form-group errorPath="name" [form]="livestreamForm">
        <label>{{ 'project.detail.livestreams.edit.name' | translate }}</label>
        <input type="text" class="input input--m" formControlName="name" />
      </app-form-group>
      <app-form-group errorPath="url" [form]="livestreamForm">
        <label>{{ 'project.detail.livestreams.edit.url' | translate }}</label>
        <input type="text" class="input input--m" formControlName="url" />
      </app-form-group>

      <div class="form-group" style="margin-top: 16px">
        <button
          type="button"
          class="btn btn-primary"
          (click)="saveLivestream()"
          [appLoading]="formLoading"
        >
          {{ 'project.detail.livestreams.edit.save' | translate }}
        </button>
        <button
          *ngIf="
            livestreamForm.get('url').value &&
            !isYoutube(livestreamForm.get('url').value)
          "
          type="button"
          class="btn btn-secondary"
          (click)="previewLivestream()"
          [appLoading]="formLoading"
        >
          {{ 'project.detail.livestreams.edit.preview' | translate }}
        </button>
      </div>
    </form>
    <div *ngIf="showLivestream" class="live-stream__container">
      <div *ngIf="showError" class="live-stream__error">
        {{ 'project.detail.livestreams.edit.preview_error' | translate }}
      </div>
      <video
        class="live-stream__video"
        tabindex="0"
        #video
        playsinline
        muted
        autoplay
      ></video>
    </div>
  </app-modal>
</section>

<app-confirm-delete
  #confirmDeleteLivestream
  (confirmed)="deleteLivestream($event)"
  [message]="'project.detail.livestreams.remove.warning' | translate"
></app-confirm-delete>
