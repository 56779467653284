<div
  class="conversation"
  [class.closed]="ticket.status == 'CLOSED'"
  [class.full-screen]="fullScreen"
>
  <div class="conversation__header" (click)="open($event)">
    <div class="conversation__header__top">
      <div *appVersion="2" class="conversation__header__top__head">
        <p>{{ ticket.subject }}</p>
        <span>{{ ticket.project.name }}</span>
      </div>
      <p *appVersion="1">{{ ticket.subject }}</p>
      <div class="conversation__header__top__icons">
        <div
          *appVersion="1"
          [hidden]="fullScreen"
          inlineSVG="/assets/img/icons/fullscreen--orange.svg"
          (click)="toggleFullScreen()"
          class="action fullscreen"
        ></div>
        <div
          *appVersion="2"
          [hidden]="fullScreen"
          inlineSVG="/assets/v2/img/icons/fullscreen.svg"
          (click)="toggleFullScreen()"
          class="action fullscreen"
        ></div>
        <div
          *appVersion="1"
          [hidden]="!fullScreen"
          inlineSVG="/assets/img/icons/normalscreen--orange.svg"
          (click)="toggleFullScreen()"
          class="action normalscreen"
        ></div>
        <div
          *appVersion="2"
          [hidden]="!fullScreen"
          inlineSVG="/assets/v2/img/icons/normalscreen.svg"
          (click)="toggleFullScreen()"
          class="action normalscreen"
        ></div>
        <div
          *appVersion="1"
          inlineSVG="/assets/img/icons/close--grey.svg"
          (click)="close()"
          class="action close"
        ></div>
        <div
          *appVersion="2"
          inlineSVG="/assets/v2/img/icons/close.svg"
          (click)="close()"
          class="action close"
        ></div>
      </div>
    </div>
    <div
      class="conversation__header__type"
      appAccess
      role="manager"
      [project]="ticket.project"
    >
      <p *appVersion="1">
        {{ 'conversation.toggle_type' | translate }}
        <span>*</span>
      </p>
      <p *appVersion="2">
        {{ 'conversation.toggle_type.v2' | translate }}
      </p>

      <div class="conversation__header__type__options flex flex-row">
        <div class="radio radio-sm radio--condensed">
          <input
            type="radio"
            name="typeQuestion{{ ticket.id }}"
            value="INFORMATION"
            [formControl]="typeControl"
            id="typeInformation{{ ticket.id }}"
          />
          <label for="typeInformation{{ ticket.id }}">{{
            'conversation.type.information' | translate
          }}</label>
        </div>
        <div class="radio radio-sm radio--condensed">
          <input
            type="radio"
            name="typeQuestion{{ ticket.id }}"
            value="QUESTION"
            [formControl]="typeControl"
            id="typeQuestion{{ ticket.id }}"
          />
          <label for="typeQuestion{{ ticket.id }}">{{
            'conversation.type.question' | translate
          }}</label>
        </div>
        <div class="radio radio-sm radio--condensed">
          <input
            type="radio"
            name="typeQuestion{{ ticket.id }}"
            value="COMPLAINT"
            [formControl]="typeControl"
            id="typeComplaint{{ ticket.id }}"
          />
          <label for="typeComplaint{{ ticket.id }}">{{
            'conversation.type.complaint' | translate
          }}</label>
        </div>
        <div class="radio radio-sm radio--condensed">
          <input
            type="radio"
            name="typeQuestion{{ ticket.id }}"
            value="COMPLIMENT"
            [formControl]="typeControl"
            id="typeCompliment{{ ticket.id }}"
          />
          <label for="typeCompliment{{ ticket.id }}">{{
            'conversation.type.compliment' | translate
          }}</label>
        </div>
      </div>

      <div class="conversation__header__type__notice" *ngIf="typeControlError">
        <span inlineSVG="/assets/img/icons/info--red.svg"></span>
        <p>{{ 'conversation.toggle_type.error' | translate }}</p>
      </div>

      <div
        class="conversation__header__type__notice"
        [hidden]="!typeSelectError"
      >
        <span inlineSVG="/assets/img/icons/info--red.svg"></span>
        <p>{{ 'conversation.status_error' | translate }}</p>
      </div>
    </div>
  </div>

  <div
    class="conversation__messages"
    #scroll
    infiniteScroll
    [infiniteScrollContainer]="scroll"
    (scrolledUp)="loadMoreMessages()"
  >
    <div class="messages">
      <div
        class="dialog-status"
        *ngIf="ticket.dialogSynchronisationStatus != null"
      >
        <img
          src="/assets/img/dialog-logo.png"
          alt="Dialog"
          class="dialog-status__logo"
        />
        <div>
          <strong>{{ 'dialog.title' | translate }}</strong>
          <div
            *ngIf="ticket.dialogSynchronisationStatus === DialogStatus.QUEUED"
          >
            {{ 'dialog.queued' | translate }}
          </div>
          <div
            *ngIf="ticket.dialogSynchronisationStatus === DialogStatus.FAILED"
          >
            {{ 'dialog.error' | translate }}
            <a href="javascript:void(0);" (click)="retryDialog()">{{
              'dialog.error.retry' | translate
            }}</a>
          </div>
          <div
            *ngIf="
              ticket.dialogSynchronisationStatus === DialogStatus.SYNCHRONIZED
            "
          >
            {{
              'dialog.synchronized'
                | translate
                  : {
                      date:
                        (ticket.dialogSynchronisationDate
                        | date : 'd MMMM yyyy - HH:mm')
                    }
            }}
          </div>
        </div>
      </div>

      <div
        class="closed"
        [hidden]="ticket.status != 'CLOSED' || !ticket.historyLoaded"
      >
        {{ 'conversation.closed.already' | translate }}
        <div class="feedback">
          <span
            class="awaiting-feedback"
            [hidden]="!ticket.requestedFeedback || ticket.feedback != null"
            >{{ 'conversation.requested_feedback' | translate }}</span
          >

          <div
            class="given-feedback"
            *ngIf="
              ticket.requestedFeedback &&
              ticket.feedback != null &&
              ticket.feedback >= 1 &&
              ticket.feedback <= 5
            "
          >
            <span class="given-feedback-label">{{
              'conversation.feedback' | translate
            }}</span>
            <div
              class="given-feedback-result"
              [inlineSVG]="
                '/assets/img/icons/smiley' + ticket.feedback + '.svg'
              "
            ></div>
          </div>
        </div>
      </div>

      <div
        *ngFor="let message of ticket.messages"
        class="message"
        [class.support]="message.projectUser != null"
        [class.device]="message.projectUser == null"
        [class.animate]="message.animate"
      >
        <div
          *appVersion="1"
          class="message__caret left"
          [inlineSVG]="'/assets/img/icons/bubble-left.svg'"
        ></div>
        <div
          *appVersion="1"
          class="message__caret right"
          [inlineSVG]="'/assets/img/icons/bubble-right.svg'"
        ></div>

        <div class="message__head">
          <ng-container *ngIf="message.name">
            <span
              class="name"
              (click)="openChangeNameModal(ticket, message, 1)"
            >
              {{ message.name }}
              <span
                *appVersion="1"
                inlineSVG="/assets/img/icons/pencil--dark.svg"
              ></span>
            </span>
            <span
              *appVersion="2"
              (click)="openChangeNameModal(ticket, message, 2)"
              class="message__head__icon"
              inlineSVG="/assets/v2/img/icons/pencil.svg"
            ></span>
          </ng-container>
          <ng-container *ngIf="!message.name && message.projectUser != null">
            <span
              class="name"
              (click)="openChangeNameModal(ticket, message, 1)"
            >
              {{ message.projectUser.firstName }}
              {{ message.projectUser.lastName }}
              <span
                *appVersion="1"
                inlineSVG="/assets/img/icons/pencil--dark.svg"
              ></span>
            </span>
            <span
              *appVersion="2"
              class="message__head__icon"
              (click)="openChangeNameModal(ticket, message, 2)"
            >
              <span inlineSVG="/assets/v2/img/icons/pencil.svg"></span>
              <span>Edit</span>
            </span>
          </ng-container>
          <span class="has-inline-spinner" [hidden]="!message.loading"></span>
        </div>

        <div class="message__content" [innerHTML]="message.content"></div>

        <div
          class="message__attachment"
          *ngIf="
            message.attachment != null || message.attachmentPreview != null
          "
        >
          <div
            (click)="openAttachment($event, message)"
            [style.background-image]="getAttachmentImage(message)"
          ></div>
        </div>

        <div class="message__date">
          {{ message.createdAt | date : 'd MMMM yyyy - HH:mm' }}
        </div>
      </div>

      <app-loader [text]="false" [hidden]="ticket.historyLoaded"></app-loader>
    </div>
  </div>

  <div class="conversation__bottom">
    <form
      appFormChangeDetector
      class="form"
      [formGroup]="form"
      (submit)="send()"
      appAccess
      role="manager"
      [project]="ticket.project"
    >
      <app-form-group [form]="form" errorPath="message" [asText]="false">
        <div
          class="conversation__bottom__attachment"
          *ngIf="uploading || attachmentReady"
        >
          <span *ngIf="uploading">{{
            'conversation.modal.uploading' | translate
          }}</span>
          <span *ngIf="attachmentReady">{{
            'conversation.modal.uploading.ready' | translate
          }}</span>
        </div>

        <div class="conversation__bottom__toolbar">
          <div
            *ngIf="!quillService.isQuillLoading() || version === 1"
            id="quill-toolbar-{{ ticket.id }}"
            class="conversation__bottom__toolbar__markup"
          >
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-link"></button>
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
          </div>

          <div class="conversation__bottom__toolbar__image">
            <app-form-group [form]="form" errorPath="attachment">
              <app-input-file
                type="ticket-message-attachment"
                [uploadImages]="true"
                [large]="false"
                (previewUpdated)="setAttachmentPreview($event)"
                (startUploading)="startUploading()"
                (stopUploading)="stopUploading()"
                [instruction]="'conversation.instruction' | translate"
                [withName]="true"
                formControlName="attachment"
              ></app-input-file>
            </app-form-group>
          </div>

          <div class="conversation__bottom__toolbar__status">
            <ng-container *appVersion="1">
              <span class="default">{{
                'conversation.status.label' | translate
              }}</span>
              <span
                class="label label-info"
                [hidden]="chosenStatus !== 'PENDING'"
                >{{ 'conversation.status.pending' | translate }}</span
              >
              <span
                class="label label-success"
                [hidden]="chosenStatus !== 'CLOSED'"
                >{{ 'conversation.status.closed' | translate }}</span
              >
              <span
                class="label label-primary"
                [hidden]="chosenStatus !== 'OPEN'"
                >{{ 'conversation.status.open' | translate }}</span
              >
            </ng-container>
            <select formControlName="status" (change)="send()">
              <option value="PENDING">
                {{ 'conversation.status.pending' | translate }}
              </option>
              <option value="CLOSED">
                {{ 'conversation.status.closed' | translate }}
              </option>
              <option value="OPEN">
                {{ 'conversation.status.open' | translate }}
              </option>
            </select>
          </div>
        </div>

        <div class="conversation__bottom__compose">
          <span
            *ngIf="ticket.status == 'CLOSED'"
            [innerHTML]="'conversation.closed' | translate"
          ></span>
          <div>
            <quill-editor
              (onEditorCreated)="editorCreated($event)"
              *ngIf="!quillService.isQuillLoading() || version === 1"
              [placeholder]="'conversation.type_message' | translate"
              formControlName="message"
              [modules]="conversationModules"
            ></quill-editor>
          </div>

          <button
            *appVersion="1"
            class="send"
            [appLoading]="uploading"
            inlineSVG="/assets/img/icons/send--orange.svg"
          ></button>
          <button
            *appVersion="2"
            class="send"
            [ngClass]="{ touched: !messageIsEmpty() }"
            [appLoading]="uploading"
          >
            Send
          </button>
        </div>
      </app-form-group>
    </form>
  </div>

  <div
    class="conversation__modal"
    [hidden]="!changeNameModalOpen"
    *ngIf="this.activeTicketMessageInModal"
  >
    <div class="conversation__modal__box">
      <div
        *appVersion="1"
        class="conversation__modal__box__close"
        inlineSVG="/assets/img/icons/close--dark.svg"
        (click)="changeNameModalOpen = false"
      ></div>
      <div
        *appVersion="2"
        class="conversation__modal__box__close"
        inlineSVG="/assets/v2/img/icons/close.svg"
        (click)="changeNameModalOpen = false"
      ></div>
      <div class="conversation__modal__box__head">
        <p *appVersion="1">
          {{ 'conversation.modal.title' | translate }}
          <span
            *ngIf="
              !this.activeTicketMessageInModal.name &&
              !this.activeTicketMessageInModal.projectUser
            "
            >{{ this.activeTicketInModal.name }}</span
          >
          <span
            *ngIf="
              !this.activeTicketMessageInModal.name &&
              this.activeTicketMessageInModal.projectUser
            "
            >{{
              this.activeTicketMessageInModal.projectUser.firstName +
                ' ' +
                this.activeTicketMessageInModal.projectUser.lastName
            }}</span
          >
          <span *ngIf="this.activeTicketMessageInModal.name">{{
            this.activeTicketMessageInModal.name
          }}</span>
        </p>
        <p *appVersion="2">
          {{ 'projects.detail.announcement.tile.edit' | translate }}
          <span>
            {{ 'conversation.update.header' | translate }}
          </span>
        </p>
      </div>
      <form
        appFormChangeDetector
        class="form"
        [formGroup]="changeNameForm"
        (submit)="saveChangeNameForm()"
        appAccess
        role="manager"
        [project]="ticket.project"
      >
        <div class="conversation__modal__box__content">
          <input
            type="text"
            class="input input--m"
            placeholder="{{ 'conversation.modal.placeholder' | translate }}"
            formControlName="name"
          />
          <button
            class="button button--m button--flat bg--orange"
            [appLoading]="loading"
          >
            <ng-container *appVersion="1">
              {{ 'conversation.modal.button' | translate }}
            </ng-container>
            <ng-container *appVersion="2">
              {{ 'projects.detail.announcement.tile.edit' | translate }}
            </ng-container>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<app-modal
  #changeLinkModal
  [title]="'conversation.modal.link.button' | translate"
>
  <div slot="header">
    {{ 'conversation.modal.link.subheader' | translate }}
  </div>
  <form [formGroup]="linkForm" (submit)="changeLink()">
    <app-form-group [form]="linkForm" errorPath="link">
      <label for="link">{{
        'conversation.modal.link.label' | translate
      }}</label>
      <input type="text" formControlName="link" id="link" />
    </app-form-group>
    <app-form-group
      [form]="linkForm"
      errorPath="customer"
      class="customer-picker"
    >
      <div class="input-button-group">
        <button class="button button--m button--flat bg--orange">
          {{ 'conversation.modal.link.button' | translate }}
        </button>
      </div>
    </app-form-group>
  </form>
</app-modal>
