<div class="bouwapp-header">
  <div class="bouwapp-header__text">
    <h1 *appVersion="1">
      {{ 'projects.detail.widget.title' | translate }}
    </h1>
    <h1 *appVersion="2">
      {{ 'projects.detail.settings.title.prefix' | translate }}
      -
      {{ 'projects.detail.widget.title.v2' | translate }}
    </h1>
    <p *appVersion="1">
      {{ 'projects.detail.widget.description' | translate }}
    </p>
  </div>
</div>

<section class="widget">
  <div class="widget__container">
    <div class="bouwapp-box" [hidden]="!project">
      <div *appVersion="2" class="bouwapp-box__title">
        <h2>
          {{ 'projects.detail.widget.title.v2' | translate }}
        </h2>
      </div>
      <div class="bouwapp-box__self">
        <div class="bouwapp-box__subheader no-margin">
          <p [innerHTML]="'projects.detail.widget.description' | translate"></p>
        </div>
        <form
          appFormChangeDetector
          [formGroup]="widgetForm"
          class="widget__form"
          (submit)="generateUrl()"
        >
          <div>
            <label for="header">{{
              'projects.detail.widget.header.label' | translate
            }}</label>
            <select
              id="header"
              formControlName="header"
              class="select select--m"
            >
              <option [value]="WidgetOption.HIDE">
                {{ 'projects.detail.widget.header.options.hide' | translate }}
              </option>
              <option [value]="WidgetOption.SHOW">
                {{ 'projects.detail.widget.header.options.show' | translate }}
              </option>
            </select>
          </div>
          <div>
            <label for="theme">{{
              'projects.detail.widget.theme.label' | translate
            }}</label>
            <select id="theme" formControlName="theme" class="select select--m">
              <option [value]="WidgetOption.LIGHT">
                {{ 'projects.detail.widget.theme.options.light' | translate }}
              </option>
              <option [value]="WidgetOption.DARK">
                {{ 'projects.detail.widget.theme.options.dark' | translate }}
              </option>
            </select>
          </div>
          <div>
            <label for="info">{{
              'projects.detail.widget.info.label' | translate
            }}</label>
            <select id="info" formControlName="info" class="select select--m">
              <option [value]="WidgetOption.HIDE">
                {{ 'projects.detail.widget.info.options.hide' | translate }}
              </option>
              <option [value]="WidgetOption.SHOW">
                {{ 'projects.detail.widget.info.options.show' | translate }}
              </option>
            </select>
          </div>
          <div></div>
          <div>
            <label for="width"
              >{{
                'projects.detail.widget.width.label' | translate
              }}
              (px)</label
            >
            <input
              type="number"
              required
              id="width"
              formControlName="width"
              class="input input--m"
            />
          </div>
          <div>
            <label for="height"
              >{{
                'projects.detail.widget.height.label' | translate
              }}
              (px)</label
            >
            <input
              type="number"
              required
              id="height"
              formControlName="height"
              class="input input--m"
            />
          </div>
          <div class="widget__form__actions">
            <button class="button button--m button--flat bg--orange">
              {{ 'projects.detail.widget.code.generate' | translate }}
            </button>

            <div class="widget__form__actions__copy">
              <div>
                <label for="code">{{
                  'projects.detail.widget.code.generated' | translate
                }}</label>
                <p>
                  <span
                    [ngxClipboard]="codeToCopy"
                    (cbOnSuccess)="textCopied()"
                  >
                    {{ 'projects.detail.widget.code.copy' | translate }}
                  </span>
                  <span [style.opacity]="copied ? 1 : 0">{{
                    'projects.detail.widget.code.copied' | translate
                  }}</span>
                </p>
              </div>
              <textarea
                rows="5"
                id="code"
                #codeToCopy
                class="textarea"
                readonly
                >{{ embedUrl }}</textarea
              >
            </div>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="iframeUrl" class="widget__self">
      <iframe
        #iframe
        frameborder="0"
        width="375"
        height="667"
        [src]="iframeUrl"
      ></iframe>
    </div>
  </div>
</section>
