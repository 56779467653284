import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PermissionType } from 'src/app/enums/permission-type';
import { UserRolesType } from 'src/app/enums/user-roles-type';
import { ProjectUser } from 'src/app/interfaces/project-user';

@Injectable({ providedIn: 'root' })
export class ManageUsersService {
  private editEventSubject = new Subject<ProjectUser>();
  editEvent$ = this.editEventSubject.asObservable();

  private deleteEventSubject = new Subject<ProjectUser>();
  deleteEvent$ = this.deleteEventSubject.asObservable();

  triggerEditEvent(projectUser: ProjectUser) {
    this.editEventSubject.next(projectUser);
  }

  triggerDeleteEvent(projectUser: ProjectUser) {
    this.deleteEventSubject.next(projectUser);
  }

  extractPrefixAndValue(input: string): { prefix: string; permission: string } {
    const [prefix, ...parts] = input.split('-');
    const permission = parts.join('-');

    return { prefix, permission };
  }

  public static determinePermissionTypeProjectScoped(
    permission: PermissionType
  ): UserRolesType {
    switch (permission) {
      case PermissionType.CREATE:
        return UserRolesType.PROJECT_ADMIN;
      case PermissionType.EDIT:
        return UserRolesType.PROJECT_USER;
      case PermissionType.VIEW:
        return UserRolesType.PROJECT_READER;
      default:
        return UserRolesType.PROJECT_READER;
    }
  }

  public getPermissionsType(user: ProjectUser): PermissionType {
    const role = this.determinePermission(user);

    switch (role) {
      case UserRolesType.ACCOUNT_ADMIN:
      case UserRolesType.PROJECT_USER:
        return PermissionType.EDIT;
      case UserRolesType.PROJECT_READER:
        return PermissionType.VIEW;
      case UserRolesType.PROJECT_ADMIN:
        return PermissionType.CREATE;
      default:
        return PermissionType.VIEW;
    }
  }

  public determinePermission(user: ProjectUser): UserRolesType {
    if (!user) return;
    for (const key in user.sortedPermissions) {
      if (user.sortedPermissions[key].length > 0) {
        const mappedKey = Object.entries(UserRolesType).find(
          ([, value]) => value === key
        );
        if (mappedKey) {
          return UserRolesType[mappedKey[0] as keyof typeof UserRolesType];
        }
      }
    }

    return UserRolesType.PROJECT_USER;
  }
}
