import { Injectable } from '@angular/core';
import { ProjectUser } from '../interfaces/project-user';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class TwoFactorService {
  private forced: boolean = false;

  constructor(private http: HttpClient) {}

  public send(method: string): Promise<ProjectUser> {
    return this.http
      .post<ProjectUser>(environment.apiUrl + '/2fa/send', { method: method })
      .toPromise();
  }

  public resend(method: string): Promise<ProjectUser> {
    return this.http
      .post<ProjectUser>(environment.apiUrl + '/2fa/resend', { method: method })
      .toPromise();
  }

  public validate(code: number): Promise<number> {
    return this.http
      .post<number>(environment.apiUrl + '/2fa/validate', { code: code })
      .toPromise();
  }

  public setForced(forced: boolean) {
    this.forced = forced;
  }

  public getForced(): boolean {
    return this.forced;
  }
}
